import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {DocsIconLarge} from '../../assets/icons';

// Styled components
import StyledButton from '../../styledComponents/button/button.styled';
import {
  StyledFlexContainerColumn,
  StyledSectionHeading,
  StyledSectionIconWrapper,
  StyledSectionInfoWrapper,
  StyledSectionParagraph
} from '../paymentsForm/templateOne/web/formWeb.styled';
import {AML_POST_MESSAGE} from '../../constants/common';

function RedirectToAML({message}) {
  const {t} = useTranslation();

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.parent.postMessage(AML_POST_MESSAGE, '*');
  };

  return (
    <StyledFlexContainerColumn gap="16px">
      <StyledSectionIconWrapper>
        <DocsIconLarge />
      </StyledSectionIconWrapper>
      <StyledSectionInfoWrapper>
        <StyledSectionHeading>
          {t('amlprocedures')}
        </StyledSectionHeading>
        <StyledSectionParagraph dangerouslySetInnerHTML={{__html: message}} />
      </StyledSectionInfoWrapper>
      <StyledButton type="button" onClick={onClick}>{t('declareyoursource')}</StyledButton>
    </StyledFlexContainerColumn>
  );
}

RedirectToAML.propTypes = {
  message: PropTypes.string.isRequired
};

export default RedirectToAML;
