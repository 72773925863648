import {createGlobalStyle} from 'styled-components';
import {MOBILE_SIZE} from '../constants/common';

const StyledGlobal = createGlobalStyle`
  @font-face {
    font-family: 'icomoon';
    src: url('https://cdn-payments.apidigi.com/Cashire/icomoon/icomoon.eot');
    src: url('https://cdn-payments.apidigi.com/Cashire/icomoon/icomoon.eot') format('embedded-opentype'),
    url('https://cdn-payments.apidigi.com/Cashire/icomoon/icomoon.ttf') format('truetype'),
    url('https://cdn-payments.apidigi.com/Cashire/icomoon/icomoon.woff') format('woff'),
    url('https://cdn-payments.apidigi.com/Cashire/icomoon/icomoon.svg') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  body {
    position: relative;
    line-height: 1.3;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }

  ol {
    padding: 0;
  }
  
  ul {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  img {
    object-fit: contain;
  }


  // scrollbar styles
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: ${({theme}) => theme.generalBackgroundColor};
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.generalCardBackgroundColor};
    cursor: pointer;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
  }

  .react-select {

    &__input {
      color: ${({theme}) => theme.inputTextColor};
    }

    &__placeholder {
      transition: 300ms;
    }

    &__value-container {
      padding: 2px 12px;
      overflow: unset !important;
    }

    &__option {
      &:active {
        background-color: ${({theme}) => theme.inputBackgroundColor} !important;
      }
    }

    &__control {
      &:hover {
        border: 1px solid ${(props) => props.theme.mainButtonBackgroundColor};

        .react-select__clear-indicator {
          opacity: 1;
        }
      }

      &:focus {
        .react-select__clear-indicator {
          opacity: 1;
        }
      }
    }

    &.hasError {
      .react-select__control {
        border: 1px solid ${(props) => props.theme.errorColor};
      }
    }
  }

  :-webkit-autofill, :-webkit-autofill:hover,
  :-webkit-autofill:focus, :-webkit-autofill:active {
    -webkit-text-fill-color: ${(props) => props.theme.inputTextColor}
  }
  
  /* Swiper Slider */
  .swiper {
    margin: -4px 0 18px;

    &-wrapper {
      .swiper-slide {
        width: auto;

        img {
          width: auto;
          max-width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    &-button-prev,
    &-button-next {
      width: 32px;
      height: 100%;
      top: 0;
      transform: translate(0, 0);
      margin-top: 0;
      background-color: ${({theme}) => theme.generalCardBackgroundColor};

      &:after {
        font-family: 'icomoon';
        content: '\\e906';
        font-size: 11px;
        font-weight: 500;
        color: ${({theme}) => theme.generalIconsColor}
      }

      &-disabled {
        opacity: 0;
        display: none;
      }
    }

    &-button-prev {
      left: -1px;
      border-radius: ${({theme}) => `${theme.sliderCornerRadiusTopLeft}px`} 0 0 ${({theme}) => `${theme.sliderCornerRadiusBottomLeft}px`};
      box-shadow: 12px 0 12px ${({theme}) => `${theme.sliderArrowShadowColor}34`};
    }

    &-button-next {
      right: -1px;
      border-radius: 0 ${({theme}) => `${theme.sliderCornerRadiusTopRight}px`} ${({theme}) => `${theme.sliderCornerRadiusBottomRight}px`} 0;
      box-shadow: -12px 0 12px ${({theme}) => `${theme.sliderArrowShadowColor}34`};

      &:after {
        transform: rotate(180deg);
      }
    }
    
    &-button-disabled {
      display: none;
    }

    @media screen and (max-width: ${MOBILE_SIZE}) {
      &-button-prev,
      &-button-next {
        display: none;
      }
    }
  }

  .apple-pay-button {
    cursor: pointer;
    display: inline-block;
    width: 100%;
    height: 36px;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: Pay; /* Use any supported button type. */
  }

  .apple-pay-button-black {
    -apple-pay-button-style: black;
  }

  .apple-pay-button-white {
    -apple-pay-button-style: white;
  }

  .apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
  }

  @supports not (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button-with-text {
      --apple-pay-scale: 1; /* (height / 32) */
      display: inline-flex;
      justify-content: center;
      font-size: 14px;
      line-height: 24px;
      border-radius: 4px;
      padding: 0;
      box-sizing: border-box;
      min-width: 100%;
      min-height: 36px;
      max-height: 64px;
    }

    .apple-pay-button-black-with-text {
      background-color: black;
      color: white;
    }

    .apple-pay-button-white-with-text {
      background-color: white;
      color: black;
    }

    .apple-pay-button-white-with-line-with-text {
      background-color: white;
      color: black;
      border: .5px solid black;
    }

    .apple-pay-button-with-text.apple-pay-button-black-with-text > .logo {
      background-image: -webkit-named-image(apple-pay-logo-white);
      background-color: black;
    }

    .apple-pay-button-with-text.apple-pay-button-white-with-text > .logo {
      background-image: -webkit-named-image(apple-pay-logo-black);
      background-color: white;
    }

    .apple-pay-button-with-text.apple-pay-button-white-with-line-with-text > .logo {
      background-image: -webkit-named-image(apple-pay-logo-black);
      background-color: white;
    }

    .apple-pay-button-with-text > .text {
      font-family: -apple-system;
      font-size: calc(1em * var(--apple-pay-scale));
      font-weight: 300;
      align-self: center;
      margin-right: calc(2px * var(--apple-pay-scale));
    }

    .apple-pay-button-with-text > .logo {
      width: calc(35px * var(--scale));
      height: 100%;
      background-size: 100% 60%;
      background-repeat: no-repeat;
      background-position: 0 50%;
      margin-left: calc(2px * var(--apple-pay-scale));
      border: none;
    }
  }

  @media screen and (max-width: ${MOBILE_SIZE}) {
    .slick-slider {
      margin-bottom: 10px;
    }
  }
`;
export default StyledGlobal;
