import styled, {css} from 'styled-components';
import StyledPaymentItem, {
  StyledPaymentHint,
  StyledPaymentItemLabel,
  StyledPaymentItemLogoWrapper,
  StyledPaymentItemValue
} from '../web/paymentItemWeb.styled';

const StyledPaymentItemMobile = styled(StyledPaymentItem)`
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px;
  grid-gap: 8px;
`;

const StyledPaymentItemLogoWrapperMobile = styled(StyledPaymentItemLogoWrapper)`
  height: 60px;
  width: 122px;
  padding: 4px;
`;

const StyledPaymentHintMobile = styled(StyledPaymentHint)`
  top: -8px;
`;

const StyledPaymentItemFigcaptionMobile = styled.figcaption`
  flex: 1;
  padding: 10px 8px 10px 0;

  ${(props) => props.isFavoriteGroup && css`
      border-right: 1px dashed ${({theme}) => `${theme.textContentColor}30`};
  `}
`;

const StyledPaymentItemLabelMobile = styled(StyledPaymentItemLabel)`
  width: 100%;
  line-height: 16px;
  font-size: ${(props) => props.theme.typographyFontSizeSmall};
`;

const StyledPaymentItemValueMobile = styled(StyledPaymentItemValue)`
  font-size: ${(props) => props.theme.typographyFontSizeSmall};
  line-height: 16px;
`;

export {
  StyledPaymentItemMobile,
  StyledPaymentItemLogoWrapperMobile,
  StyledPaymentItemFigcaptionMobile,
  StyledPaymentItemLabelMobile,
  StyledPaymentItemValueMobile,
  StyledPaymentHintMobile
};
