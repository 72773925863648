import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import PaymentItemWebTemplateTwo from './paymentItem';
import Search from '../../../search/search';
import NavigationTabsWeb from '../../../navigationTabs/navigationTabsWeb/navigationTabsWeb';
import {groupNameMapping} from '../../../../constants/common';
import BonusBanner from '../../../bonusBanner/bonusBanner';


/* Styled Components */
import {StyledDashedHr} from '../../../../styledComponents/button/button.styled';

/* Styled Components Imports from Templates Two styled files*/
import StyledMainWrapper from './listTemplateTwo.styled';
import {
  StyledPaymentItemSection,
  StyledGroupName,
  StyledLine,
  StyledPsWrapper,
  StyledPsWrapperMobile
} from './paymentItem.styled';

function ListTemplateTwo({
  paymentData,
  handlePaymentSystemDetailsPage,
  currency,
  isCurrencySymbol,
  enableSearch,
  isFavoriteGroup,
  onFavoriteClickHandler,
  customizationTheme,
  deviceType,
  hasTabs,
  activeTab,
  setActiveTab,
  translations,
  isBonusBanner,
  bonusBannerUrl,
  goToBonusHandler,
  isMobileVersion
}) {
  const [tabVersion, setTabVersion] = useState(customizationTheme?.navigationTabVersion?.value || 'versionOne');

  useEffect(() => {
    setTabVersion(customizationTheme?.navigationTabVersion?.value);
  }, [customizationTheme?.navigationTabVersion?.value]);

  const handleTabClick = (event) => {
    const {name} = event.currentTarget;
    setActiveTab(name);
  };

  const StyledPsWrapperComponent = isMobileVersion ? StyledPsWrapperMobile : StyledPsWrapper;

  return (
    <>
      <div>
        {hasTabs && paymentData?.length ? (
          <NavigationTabsWeb
            tabVersion={tabVersion}
            handleTabClick={handleTabClick}
            activeTab={activeTab}
            deviceType={deviceType}
          />
        ) : null}
        {enableSearch ? <Search /> : null}
      </div>
      <StyledMainWrapper>
        {isBonusBanner ? (
          <BonusBanner bannerUrl={bonusBannerUrl} goToBonusHandler={goToBonusHandler} />
        ) : null}
        {paymentData?.map((pasItem, indexOne) => (
          // eslint-disable-next-line react/no-array-index-key
          <StyledPaymentItemSection key={`${pasItem.groupId}:${indexOne}`}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {pasItem.groupId !== 0 ? (
              <StyledGroupName>
                test
                {translations[groupNameMapping[pasItem?.groupId]?.toLowerCase()]}
                <StyledLine />
              </StyledGroupName>
            ) : paymentData.length > 1 ? <StyledDashedHr hasMarginBottom /> : null}
            <StyledPsWrapperComponent>
              {/* eslint-disable-next-line no-shadow */}
              {pasItem?.cashierPaymentSystem?.map((paymentInfo, indexTwo) => (
                <PaymentItemWebTemplateTwo
                  isCurrencySymbol={isCurrencySymbol}
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={`${paymentInfo?.paymentSystemId}:${indexTwo}`}
                  paymentInfo={paymentInfo}
                  handlePaymentSystemDetailsPage={handlePaymentSystemDetailsPage}
                  isFavoriteGroup={isFavoriteGroup}
                  onFavoriteClickHandler={onFavoriteClickHandler}
                  currency={currency}
                />
              ))}
            </StyledPsWrapperComponent>
          </StyledPaymentItemSection>
        ))}
      </StyledMainWrapper>
    </>
  );
}

ListTemplateTwo.defaultProps = {
  paymentData: null,
  handlePaymentSystemDetailsPage: null,
  currency: null,
  enableSearch: false,
  onFavoriteClickHandler: null,
  isFavoriteGroup: false,
  deviceType: null,
  isBonusBanner: false,
  bonusBannerUrl: null
};

ListTemplateTwo.propTypes = {
  paymentData: PropTypes.array,
  handlePaymentSystemDetailsPage: PropTypes.func,
  onFavoriteClickHandler: PropTypes.func,
  currency: PropTypes.string,
  enableSearch: PropTypes.bool,
  isFavoriteGroup: PropTypes.bool,
  isCurrencySymbol: PropTypes.bool.isRequired,
  deviceType: PropTypes.number,
  customizationTheme: PropTypes.object.isRequired,
  hasTabs: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired,
  isBonusBanner: PropTypes.bool,
  bonusBannerUrl: PropTypes.string,
  goToBonusHandler: PropTypes.func.isRequired,
  isMobileVersion: PropTypes.bool.isRequired
};

export default ListTemplateTwo;
