import * as Yup from 'yup';
import sha256 from 'crypto-js/sha256';
import CryptoJS from 'crypto-js';
import {currencyList} from '../constants/currencyList';
import {DEFAULT_LANGUAGE, TIME_UNITS} from '../constants/common';

/**
 * Retrieve the localized value from the provided property descriptions based on the given language.
 * If the value for the specified language is not available, it falls back to the default language.
 * @param {Object} propertyDescriptions - Object containing property descriptions for different languages.
 * @param {string} language - The language code for which the value is requested.
 * @returns {string} - The localized value for the specified language, or the default language if not available.
 */
const getLocalizedValue = (propertyDescriptions, language) => propertyDescriptions[language] || propertyDescriptions[DEFAULT_LANGUAGE];

/**
 * Retrieve control property descriptions for the specified language from the provided field controls.
 * If the language is not specified, it falls back to the default language.
 * @param {Object[]} fieldControls - An array of field control objects.
 * @param {string} [language] - The language code for which the descriptions are requested. If not provided, the default language is used.
 * @returns {Object} - An object containing control property descriptions keyed by control property type name.
 */
const getControlPropertyDescriptions = (fieldControls, language) => fieldControls.reduce((controlProperties, control) => {
  const {
    controlPropertyTypeName,
    propertyDescriptions
  } = control;
  controlProperties[controlPropertyTypeName] = getLocalizedValue(
    propertyDescriptions,
    language || DEFAULT_LANGUAGE
  );
  return controlProperties;
}, {});

/**
 * Generate validation schemas for dynamic controls based on the provided control properties and formatting.
 * @param {Object[]} dynamicControls - An array of dynamic control objects.
 * @param {Object} formattedControls - An object containing formatted control properties.
 * @param {Object} validationSchemas - An object to store generated validation schemas.
 * @param {string} lang - The language code used for localization.
 * @returns {Object} - The updated validation schemas object containing validation rules for each dynamic control.
 */
export const controlValidationScheme = (
  dynamicControls,
  formattedControls,
  validationSchemas,
  lang
) => {
  dynamicControls?.forEach((curr) => {
    const controlProperties = getControlPropertyDescriptions(
      curr.fieldControls,
      lang
    );

    const isRequired = formattedControls[curr.key]?.hidden?.value?.toString() === 'true'
      ? false
      : formattedControls[curr.key]?.required?.value === 'true';

    const isRegexpRequired = formattedControls[curr.key]?.hidden?.value?.toString() === 'true'
      ? false
      : formattedControls[curr.key]?.regexp?.value;
    let schema = Yup.string();

    if (isRequired) {
      schema = schema.required(controlProperties.required);
    }
    if (isRegexpRequired) {
      schema = schema.matches(
        new RegExp(formattedControls[curr.key]?.regexp?.value),
        controlProperties.regexp
      );
    }
    validationSchemas[curr.key] = schema;
  });

  return validationSchemas;
};

/**
 * Format dynamic controls into a structured object containing control properties.
 * @param {Object[]} dynamicControls - An array of dynamic control objects.
 * @returns {Object} - An object containing formatted control properties grouped by control keys.
 */
const formattedControls = (dynamicControls) => dynamicControls?.reduce((acc, fieldControlItem) => {
  acc[fieldControlItem?.key] = fieldControlItem?.fieldControls?.reduce(
    (obj, fieldControl) => {
      obj[fieldControl?.controlPropertyTypeName] = fieldControl;
      return obj;
    },
    {}
  );
  return acc;
}, {});

/**
 * Generate initial values for dynamic controls.
 * @param {Object[]} dynamicControls - An array of dynamic control objects.
 * @returns {Object} - An object containing initial values keyed by control keys.
 */
const initialValues = (dynamicControls) => dynamicControls?.reduce((acc, fieldControlItem) => {
  if (fieldControlItem?.key !== null) {
    acc[fieldControlItem?.key] = '';
  }
  return acc;
}, {});

/**
 * Computes the SHA256 hash from a given input string.
 *
 * @param {string} input - The input string to compute the hash from.
 * @returns {string} The SHA256 hash in hexadecimal format.
 */
export const computeSha256HashFromString = (input) => {
  const hash = sha256(input);
  return hash.toString(CryptoJS.enc.Hex);
};


/**
 * Returns either the currency symbol or the code based on the setting.
 * @param {string} currency - The currency code.
 * @param {boolean} isCurrencySymbol - The isCurrencySymbol to determine whether to return the symbol or the code.
 * @returns {string} The currency symbol or code based on the setting.
 */

const getCurrencySymbolOrCode = (currency, isCurrencySymbol) => {
  const upperCaseCurrency = currency?.toUpperCase();
  return isCurrencySymbol && currencyList[upperCaseCurrency]
    ? currencyList[upperCaseCurrency].symbol
    : upperCaseCurrency;
};

function formatDate(dateString) {
  const options = {
    year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false
  };
  const date = new Date(dateString);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const adjustedDate = new Date(date.getTime() - userTimezoneOffset);
  return new Intl.DateTimeFormat('de-DE', options).format(adjustedDate).replace(',', '');
}

export const sortArrayByKeyValue = ({array, key, value}) => {
  if (array?.length) {
    if (value === '-1') {
      return array;
    }
    const customSort = (a, b) => {
      if (a[key] === value) {
        return -1;
      }
      if (b[key] === value) {
        return 1;
      }
      return 0;
    };

    return array.sort(customSort);
  }

  return null;
};

export const arrayBufferToBase64 = (buffer) => {
  const bytes = new Uint8Array(buffer);
  const binary = bytes.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
  return window.btoa(binary);
};

export const getControlPropertyValues = (fieldControls, language) => fieldControls.reduce((controlProperties, {
  controlPropertyTypeName,
  propertyDescriptions,
  value
}) => {
  const localizedDescription = getLocalizedValue(
    propertyDescriptions,
    language || DEFAULT_LANGUAGE
  );
  controlProperties[controlPropertyTypeName] = localizedDescription ?? value;
  return controlProperties;
}, {});

export const generatePaymentDataForBonusList = (paymentsList, simplifiedData) => paymentsList?.map((payment) => {
  const {
    disableMethod, unavailable, hasRedirect, hasVerifiedAccount
  } = simplifiedData?.find((item) => item?.paymentSystemId === payment?.id) ?? {};
  return {
    ...payment, disableMethod, unavailable, hasRedirect, hasVerifiedAccount
  };
});

export const findMinMaxAmountsForBonus = (conditions) => {
  let minAmount = Number.MAX_SAFE_INTEGER;
  let maxAmount = Number.MIN_SAFE_INTEGER;

  conditions.forEach(({minDeposit, maxDeposit}) => {
    if (minDeposit < minAmount) {
      minAmount = minDeposit;
    }
    if (maxDeposit > maxAmount) {
      maxAmount = maxDeposit;
    }
  });

  return [minAmount, maxAmount];
};

export const calculateBonusExpiration = (expirationPeriod) => {
  const days = Math.floor(expirationPeriod / 24);
  const hours = Math.floor(expirationPeriod % 24);

  return `${days}d:${hours}h`;
};

/**
 * Validates card number input based on Luhn algorithm.
 * @param {string} cardNumber - The card number.
 * @returns {boolean} is inserted card number valid or not.
 */
export const validateCardNumberInput = (cardNumber) => {
  if (cardNumber?.length > 13 && cardNumber.length < 21 && cardNumber[0] !== '0') {
    const numbersCountReminder = cardNumber.length % 2;
    let acc = 0;

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = cardNumber.length; i < len; ++i) {
      let num = Number(cardNumber[i]);
      if (i % 2 === numbersCountReminder) {
        num *= 2;
        if (num > 9) num -= 9;
      }
      acc += num;
    }

    return acc % 10 === 0;
  }

  return false;
};

/**
 * Validates card number input value from form values.
 * @param {object} payloadValues - The card number.
 * @returns {boolean}
 */
export const validateCardNumberFromPayloadValues = (payloadValues) => {
  const cardNumberKey = Object.keys(payloadValues)?.find((key) => key.toLowerCase().includes('cardnumber'));
  if (cardNumberKey) {
    return validateCardNumberInput(payloadValues[cardNumberKey]);
  }

  return true;
};

const renderProcessingTime = (processingTimeDetail, t) => {
  if (processingTimeDetail?.processingTimeFrom !== null) {
    let unit;
    switch (processingTimeDetail?.processingTimeUnit) {
      case TIME_UNITS.HOURLY:
        unit = t(('hourly').toLowerCase());
        break;
      case TIME_UNITS.MINUTE:
        unit = t(('minute').toLowerCase());
        break;
      case TIME_UNITS.INSTANT:
        return t(('instant').toLowerCase());
      default:
        unit = null;
        break;
    }
    return `${processingTimeDetail?.processingTimeFrom} - ${processingTimeDetail?.processingTimeTo} ${unit}`;
  }
  return null;
};

export const decodeHTML = (html) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

export {
  getLocalizedValue,
  getControlPropertyDescriptions,
  formattedControls,
  initialValues,
  getCurrencySymbolOrCode,
  formatDate,
  renderProcessingTime
};
