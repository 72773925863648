import React, {
  useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import PaymentCardTemplateTwo from '../../../paymentCardTemplateTwo/paymentCardTemplateTwo';
import RenderPaymentForm from '../../common/renderPaymentForm';
import EnhancedFormik from '../../common/enhancedFormik';
import {renderProcessingTime} from '../../../../helpers/common';
import {DEFAULT_PAYMENT_ALERTS, DEVICE_TYPE, TEMPLATE_TYPE} from '../../../../constants/common';
import {CashierDetailsContext} from '../../../../contexts/cashierDetailsContext';
import {PaymentFormCallbacksContext} from '../../../../contexts/paymentFormCallbacksContext';
import {SelectedPaymentDetailsContext} from '../../../../contexts/selectedPaymentDetailsContext';
import {AccountVerificationHistoryContext} from '../../../../contexts/accountVerificationHistoryContext';
import GeneratedActiveCodesList from '../../../generatedActiveCodesList/generatedActiveCodesList';

/* Styled Components */
import {
  StyledMainWrapper,
  StyledPaymentItemLabel,
  StyledPaymentItemValue
} from '../../../paymentsList/templateOne/web/paymentItemWeb.styled';
import {StyledFormWrapper} from '../../../../styledComponents/input/input.styled';
import StyledSwitcherContainer, {
  StyledSwitchLabel
} from '../../../toggle/toggled.styled';
import {StyledIconSmall} from '../../../../styledComponents/icon/icon.styled';
import {StyledButtonWithoutBg} from '../../../../styledComponents/button/button.styled';

function FormMobileTemplateTwo({
  paymentGeneratedCodeInfo,
  setPaymentGeneratedCodeInfo,
  applePaySession,
  baseUrl,
  alertContext,
  isCurrencySymbol,
  payPalTransactionData,
  googlePayTransactionData,
  isDefaultAvailable,
  onPaymentDefaultStatusUpdateHandler,
  paymentControlsData,
  activeCodesList,
  showActiveCodesList
}) {
  const {t} = useTranslation();

  const {
    operationType, currency, siteId, userId
  } = useContext(CashierDetailsContext);
  const {isDefault: initialIsDefault, paymentSystemId} = useContext(SelectedPaymentDetailsContext);
  const {backToListHandler, openActiveCodesList, getVerificationHistory} = useContext(PaymentFormCallbacksContext);

  const [showGeneratedCodeInfo, setShowGeneratedCodeInfo] = useState(false);
  const [verificationHistory, setVerificationHistory] = useState([]);
  const [isDefault, setIsDefault] = useState(initialIsDefault || false);

  const closeActiveCodeListHandler = useCallback(() => {
    openActiveCodesList(false);
  }, [openActiveCodesList]);

  const backToGeneratorHandler = useCallback(() => {
    if (typeof setPaymentGeneratedCodeInfo === 'function') {
      setPaymentGeneratedCodeInfo([]);
    }
    setShowGeneratedCodeInfo(false);
  }, [setPaymentGeneratedCodeInfo]);

  const defaultToggleHandler = useCallback(() => {
    onPaymentDefaultStatusUpdateHandler({
      isDefault: !isDefault,
      callback: (res) => {
        if (res.data) {
          alertContext?.success(t((DEFAULT_PAYMENT_ALERTS[operationType][isDefault ? 'unSet' : 'set']).toLowerCase()));
          setIsDefault((prev) => !prev);
        } else {
          alertContext?.error(t((DEFAULT_PAYMENT_ALERTS[operationType].error).toLowerCase()));
        }
      }
    });
  }, [isDefault, alertContext]);

  const verificationHistoryContextMemoized = useMemo(() => ({
    getVerificationHistoryHandler: () => {
      getVerificationHistory({
        siteId,
        userId,
        paymentSystemId,
        setVerificationHistory
      });
    },
    clearVerificationHistoryHandler: () => {
      setVerificationHistory([]);
    },
    verificationHistory
  }), [verificationHistory]);

  /**
   * Effect for handling initialIsDefault change in case of payment details change
   */
  useEffect(() => {
    setIsDefault(initialIsDefault);
  }, [initialIsDefault, paymentSystemId]);

  /**
   * Effect to handle changes in paymentGeneratedCodeInfo fields length
   */
  useEffect(() => {
    const hasGeneratedInfo = paymentGeneratedCodeInfo?.length;
    setShowGeneratedCodeInfo(!!hasGeneratedInfo);
  }, [paymentGeneratedCodeInfo?.length]);

  return (
    <AccountVerificationHistoryContext.Provider value={verificationHistoryContextMemoized}>
      <StyledMainWrapper>
        <div>
          <StyledButtonWithoutBg
            isPositionLeft
            type="button"
            onClick={backToListHandler}
          >
            <StyledIconSmall icon="arrowLeft" fontSize="11px" />
            Back To Payments Page

            {/* For Opening Details Modal */}
            <StyledIconSmall icon="warning" fontSize="11px" />
          </StyledButtonWithoutBg>
          <StyledFormWrapper>
            <PaymentCardTemplateTwo
              currency={currency}
              isCurrencySymbol={isCurrencySymbol}
              expand={() => {}}
              paymentInfo={{
                imagePath: paymentControlsData?.imagePath,
                paymentSystemName: paymentControlsData?.paymentSystemName
              }}
            />
            {/* eslint-disable-next-line no-nested-ternary */}
            {showGeneratedCodeInfo ? (
              <GeneratedActiveCodesList
                isMobile
                codesList={[{data: paymentGeneratedCodeInfo}]}
                onGoBackHandler={backToGeneratorHandler}
                alertContext={alertContext}
                currency={currency}
                isCurrencySymbol={isCurrencySymbol}
              />
            ) : showActiveCodesList && activeCodesList.length > 0 ? (
              <GeneratedActiveCodesList
                isMobile
                codesList={activeCodesList}
                onGoBackHandler={closeActiveCodeListHandler}
                alertContext={alertContext}
                currency={currency}
                isCurrencySymbol={isCurrencySymbol}
              />
            ) : (
              <>
                <EnhancedFormik
                  baseUrl={baseUrl}
                  applePaySession={applePaySession}
                  alertContext={alertContext}
                  payPalTransactionData={payPalTransactionData}
                  googlePayTransactionData={googlePayTransactionData}
                  formComponent={RenderPaymentForm}
                  deviceType={DEVICE_TYPE.WEB}
                  currency={currency}
                  isCurrencySymbol={isCurrencySymbol}
                  paymentControlsData={paymentControlsData}
                  templateType={TEMPLATE_TYPE.TEMPLATE_TWO}
                />
                {isDefaultAvailable && (
                  <StyledSwitcherContainer>
                    <input
                      type="checkbox"
                      checked={isDefault}
                      onChange={defaultToggleHandler}
                    />
                    <StyledSwitchLabel>{t(('setAsDefault')?.toLowerCase())}</StyledSwitchLabel>
                  </StyledSwitcherContainer>
                )}
                {paymentControlsData?.processingTimeDetail ? (
                  <div>
                    <StyledPaymentItemLabel>
                      {t(('transactionTime')?.toLowerCase())}
                      :
                      <StyledPaymentItemValue>
                        {renderProcessingTime(paymentControlsData?.processingTimeDetail, t)}
                      </StyledPaymentItemValue>
                    </StyledPaymentItemLabel>
                  </div>
                ) : null}
              </>
            )}
          </StyledFormWrapper>
        </div>
      </StyledMainWrapper>
    </AccountVerificationHistoryContext.Provider>
  );
}

FormMobileTemplateTwo.defaultProps = {
  paymentGeneratedCodeInfo: null,
  setPaymentGeneratedCodeInfo: null,
  // paymentDescription: null,
  // paymentSystemId: null,
  payPalTransactionData: null,
  googlePayTransactionData: null,
  // paymentBalances: null,
  // paymentLimits: null,
  isDefaultAvailable: false,
  onPaymentDefaultStatusUpdateHandler: null,
  // paymentSources: null
  activeCodesList: [],
  showActiveCodesList: false
};

FormMobileTemplateTwo.propTypes = {
  // deviceType: PropTypes.number.isRequired,
  paymentGeneratedCodeInfo: PropTypes.array,
  setPaymentGeneratedCodeInfo: PropTypes.func,
  // paymentDescription: PropTypes.array,
  // paymentSystemId: PropTypes.number,
  applePaySession: PropTypes.bool.isRequired,
  baseUrl: PropTypes.string.isRequired,
  // translations: PropTypes.object.isRequired,
  alertContext: PropTypes.object.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  payPalTransactionData: PropTypes.object,
  googlePayTransactionData: PropTypes.object,
  // paymentBalances: PropTypes.object,
  // paymentLimits: PropTypes.object,
  isDefaultAvailable: PropTypes.bool,
  onPaymentDefaultStatusUpdateHandler: PropTypes.func,
  // paymentSources: PropTypes.array,
  paymentControlsData: PropTypes.object.isRequired,
  activeCodesList: PropTypes.array,
  showActiveCodesList: PropTypes.bool
  // newly added props
  // showGeneratedCodeInfo: PropTypes.bool.isRequired,
  // closeActiveCodeListHandler: PropTypes.func.isRequired,
  // goBackToCodeGeneratorHandler: PropTypes.func.isRequired
};
export default FormMobileTemplateTwo;
