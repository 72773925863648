import instance from './apiService';
import Config from '../configs/mainConfig';
import {objectHasNonNullValue} from '../helpers/helperFunction';

export const getPaymentBalances = ({
  setPaymentBalances,
  alertContext,
  setIsLoading,
  siteId,
  paymentSystemId,
  userId,
  languageISO,
  token
}) => instance.get(
  `${Config.apiURLs.getPaymentBalances}/${siteId}/${paymentSystemId}/${userId}/${languageISO}/${token}`,
  {
    param: {
      siteId,
      paymentSystemId,
      userId,
      languageISO,
      token
    }
  }
)
  .then((response) => {
    if (objectHasNonNullValue(response?.data)) {
      setPaymentBalances(response.data);
    }
    return response.data;
  })
  .catch((error) => {
    alertContext.error(error.response.data);
    throw error;
  }).finally(() => setIsLoading(false));
