import styled from 'styled-components';
import {
  flexColumnContainer,
  flexContainer,
  fontStyle,
} from '../../../../helpers/styled';

const StyledBonusInfoWrapper = styled.section`
  display: flex;
  margin-top: 16px;
  gap: 40px;
`;

const StyledBonusInfoContent = styled.div`
  flex: 1;
`;

const StyledBonusInfoDescription = styled.div`
  border-bottom: 1px dashed ${({theme}) => `${theme.textContentColor}30`};
  ${fontStyle('regular')};
  color: ${({theme}) => theme.textContentColor};
    
    ul {
      list-style-type: disc;
      padding-left: 16px;
    }
`;

const StyledBonusInfoList = styled.ul`
  ${flexColumnContainer()};
  gap: 12px;
  width: 304px;
  padding: 24px;
  border-radius: 12px;
  background-color: #213245;
`;

const StyledBonusInfoListItem = styled.li`
  min-height: 30px;
  ${flexContainer()};
  justify-content: flex-start;
  gap: 12px;
`;

const StyledBonusInfoListItemKey = styled.h3`
  ${fontStyle('regular')};
  color: ${({theme}) => theme.textContentColor};
`;

const StyledBonusInfoListItemValue = styled.span`
  color: ${({theme}) => theme.textTitleColor};
  margin-left: 8px;
`;

export default StyledBonusInfoWrapper;
export {
  StyledBonusInfoDescription,
  StyledBonusInfoList,
  StyledBonusInfoListItem,
  StyledBonusInfoListItemKey,
  StyledBonusInfoListItemValue,
  StyledBonusInfoContent
};
