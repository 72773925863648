import React, {
  memo, useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {PaymentBonusesContext} from '../../contexts/paymentBonusesContext';
import {findMinMaxAmountsForBonus, getCurrencySymbolOrCode} from '../../helpers/common';
import {AMOUNT_MESSAGE_STATUSES} from '../../constants/common';
import {StyledButtonWithoutBg} from '../../styledComponents/button/button.styled';
import {StyledIconSmall} from '../../styledComponents/icon/icon.styled';
import PaymentBonusesListMobile from '../paymentBonusesList/paymentBonusesListMobile';
import PaymentBonusesListWeb from '../paymentBonusesList/paymentBonusesListWeb';

let promoCodeInputTimout = null;

function PaymentBonusField({
  isMobile, amount, currency, isCurrencySymbol
}) {
  const {t} = useTranslation();

  const {selectedBonusId, paymentBonuses, setSelectedBonusId} = useContext(PaymentBonusesContext);

  const [showPromoCodeInput, setShowPromoCodeInput] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [showAllBonuses, setShowAllBonuses] = useState(false);

  const selectedBonusConditions = !!selectedBonusId ? paymentBonuses.find(({id}) => id === selectedBonusId).conditions : null;
  const [minAmount, maxAmount] = !!selectedBonusConditions ? findMinMaxAmountsForBonus(selectedBonusConditions) : [];

  const [amountMessage, messageStatus] = useMemo(() => {
    if (amount === 0) {
      return [t('correctamountmessage'), AMOUNT_MESSAGE_STATUSES.DEFAULT];
    }
    if (amount < minAmount) {
      return [t('lessamountmessage'), AMOUNT_MESSAGE_STATUSES.ERROR];
    }
    if (amount > maxAmount) {
      return [t('moreamountmessage'), AMOUNT_MESSAGE_STATUSES.ERROR];
    }

    const {bonusAmount, bonusPercent} = selectedBonusConditions?.find(({minDeposit, maxDeposit}) => (amount >= minDeposit && amount <= maxDeposit)) || {};
    // eslint-disable-next-line no-mixed-operators
    return [`${t('recive')}: ${bonusAmount || (amount * bonusPercent / 100)}${getCurrencySymbolOrCode(currency, isCurrencySymbol)}`, AMOUNT_MESSAGE_STATUSES.SUCCESS];
  }, [amount, minAmount, maxAmount, selectedBonusConditions]);

  const {webImage, name} = useMemo(() => {
    if (selectedBonusId && paymentBonuses?.length > 0) {
      return paymentBonuses.find((bonus) => bonus.id === selectedBonusId);
    }
    return {};
  }, [selectedBonusId, paymentBonuses]);

  const promoCodeButton = (
    <button type="button" onClick={() => setShowPromoCodeInput(true)}>{t('IHavePromoCode')}</button>
  );

  const onClosePromoCodeHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPromoCodeInput(false);
    setPromoCode('');
  };

  const onOpenAllBonusesHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowAllBonuses(true);
  };

  const onCloseAllBonusesHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowAllBonuses(false);
  }, []);

  const onSelectBonusHandler = useCallback((bonusId) => () => {
    setSelectedBonusId(bonusId);
  }, [setSelectedBonusId]);

  const onCancelBonusHandler = useCallback(() => {
    setSelectedBonusId(null);
  }, [setSelectedBonusId]);

  useEffect(() => () => {
    clearTimeout(promoCodeInputTimout);
  }, []);

  useEffect(() => {
    clearTimeout(promoCodeInputTimout);
    promoCodeInputTimout = setTimeout(() => {
      if (!!promoCode) {
        const numericPromoCode = Number(promoCode);
        if (paymentBonuses?.find((bonus) => bonus.id === numericPromoCode)) {
          setSelectedBonusId(numericPromoCode);
          setShowPromoCodeInput(false);
          setPromoCode('');
        }
      }
    }, 1000);
  }, [promoCode]);

  return paymentBonuses?.length > 0 ? (
    <div>
      <div>
        {/* for first row */}
        {showPromoCodeInput ? (
          <div style={{display: 'flex'}}>
            <input type="number" value={promoCode} onChange={(e) => setPromoCode(e.target.value)} />
            <StyledButtonWithoutBg type="button" onClick={onClosePromoCodeHandler}>
              <StyledIconSmall icon="close" fontSize="11px" />
            </StyledButtonWithoutBg>
          </div>
        ) : (
          <div style={{display: 'flex'}}>
            {selectedBonusId ? (
              <div style={{display: 'flex'}}>
                <img src={webImage} alt="" />
                <p>{name}</p>
                <StyledButtonWithoutBg type="button" onClick={() => setSelectedBonusId(null)}>
                  <StyledIconSmall icon="close" fontSize="11px" />
                </StyledButtonWithoutBg>
              </div>
            ) : promoCodeButton}
            <div style={{display: 'flex'}}>
              {selectedBonusId ? null : <p>{t('chooseBonus')}</p>}
              <button type="button" onClick={onOpenAllBonusesHandler}>icon</button>
            </div>
          </div>
        )}
        {/* for amount message */}
        {!!selectedBonusId && !!selectedBonusConditions ? (
          <div>
            <p>{amountMessage}</p>
          </div>
        ) : null}
        {!!selectedBonusId && !showPromoCodeInput ? promoCodeButton : null}
      </div>
      {/* section for bonuses list */}
      {isMobile ? (
        <PaymentBonusesListMobile
          showInfo={showAllBonuses}
          bonusesList={paymentBonuses}
          selectedBonusId={selectedBonusId}
          onSelectBonusHandler={onSelectBonusHandler}
          onCancelBonusHandler={onCancelBonusHandler}
          onCloseListHandler={onCloseAllBonusesHandler}
        />
      ) : null}
      {!isMobile && showAllBonuses ? (
        <PaymentBonusesListWeb
          bonusesList={paymentBonuses}
          selectedBonusId={selectedBonusId}
          onSelectBonusHandler={onSelectBonusHandler}
          onCancelBonusHandler={onCancelBonusHandler}
          onCloseListHandler={onCloseAllBonusesHandler}
        />
      ) : null}
    </div>
  ) : null;
}

PaymentBonusField.propTypes = {
  amount: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired
};

export default memo(PaymentBonusField);
