import styled, {css} from 'styled-components';
import {
  flexContainer, fontStyle, getBorder, getBorderRadius
} from '../../helpers/styled';

const StyledAlertBox = styled.div`
  ${flexContainer()};
  padding: 8px 12px 8px;
  margin-bottom: 16px;
  grid-gap: 8px;
  background-color: ${(props) => props.theme.notificationInfoBackgroundColor};
  border: ${(props) => getBorder(props, 'notifications')};
  border-radius: ${(props) => getBorderRadius(props, 'notifications')};
  
  svg {
    min-width: 32px;
  }

  ${(props) => props.isMobile && css`
    flex-wrap: wrap;
    grid-gap: 4px;
    padding-left: 16px;
  `}
`;

const StyledAlertMessageWrapper = styled.div`
  ${flexContainer()};
  justify-content: flex-start;
  grid-gap: 8px;
`;

const StyledAlertMessage = styled.p`
  ${fontStyle('regular')};
  font-size: ${(props) => props.theme.typographyFontSizeMiddle};
`;

export {
  StyledAlertBox,
  StyledAlertMessageWrapper,
  StyledAlertMessage
};
