import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {UPDATE_PROFILE_MESSAGE} from '../../constants/common';
import {InfoIconBlue} from '../../assets/icons';

import {StyledInfoLinkButton} from '../../styledComponents/button/button.styled';
import {
  StyledAlertBox,
  StyledAlertMessage,
  StyledAlertMessageWrapper
} from './updateProfileNote.styled';

function UpdateProfileNote({message, isMobile}) {
  const {t} = useTranslation();

  const onClickHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    window.parent.postMessage(UPDATE_PROFILE_MESSAGE, '*');
  };

  return (
    <StyledAlertBox
      spaceBetween
      isMobile={isMobile}
    >
      <StyledAlertMessageWrapper>
        {isMobile ? null : (<InfoIconBlue />)}
        <StyledAlertMessage>{message}</StyledAlertMessage>
      </StyledAlertMessageWrapper>
      <StyledInfoLinkButton type="button" onClick={onClickHandler}>{t('updateprofile')}</StyledInfoLinkButton>
    </StyledAlertBox>
  );
}

UpdateProfileNote.propTypes = {
  message: PropTypes.string.isRequired,
  isMobile: PropTypes.bool
};

UpdateProfileNote.defaultProps = {
  isMobile: false
};

export default UpdateProfileNote;
