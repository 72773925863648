export const defaultTheme = {
  // input
  inputBackgroundColor: '#3E3E3E',
  inputTextColor: '#ECECEC',
  inputLabelColor: '#ECECEC',
  inputBorderColor: '#464646',
  inputHasLabel: false,
  inputHasBorder: true,
  inputBorderWidth: '1px',
  defaultInputBorderWidth: '0',
  inputCornerRadiusTopLeft: '12',
  inputCornerRadiusTopRight: '12',
  inputCornerRadiusBottomRight: '12',
  inputCornerRadiusBottomLeft: '12',
  inputBorderStyle: 'solid',

  /* This part should be updated after ICE */
  inputBorder: '1px',
  inputBorderTop: '',
  inputBorderBottom: '',
  inputBorderLeft: '',
  inputBorderRight: '',

  // general
  generalBackgroundColor: '#080808',
  generalFontFamily: 'Roboto',

  // Group Header
  groupHeaderBackgroundColor: '#313131',
  groupHeaderTitleColor: '#ADADAD',
  groupHeaderCornerRadiusTopLeft: '16',
  groupHeaderCornerRadiusTopRight: '16',
  groupHeaderCornerRadiusBottomRight: '16',
  groupHeaderCornerRadiusBottomLeft: '16',
  groupHeaderBorderColor: '',
  /*
  Combine them into a single variable, groupHeaderBorderWidth.
  Additionally, introduce groupHeaderBorderDirection and groupHeaderHasBorder variables.
  Following these changes, implement a function in the Cashier
  Library to detect where to add the border, specifying the width and color accordingly.
  * */
  groupHeaderHasBorder: false,
  defaultGroupHeaderBorderWidth: '0',
  groupHeaderBorderWidth: '1',
  groupHeaderBorderWidthTop: '',
  groupHeaderBorderWidthBottom: '',
  groupHeaderBorderWidthLeft: '',
  groupHeaderBorderWidthRight: '',


  // Card Label Style
  cardNewLabelBackgroundColor: '#00A410',
  cardNewLabelTextColor: '#fff',
  cardUnderMaintenanceLabelTextColor: '#ffffff',
  cardUnderMaintenanceLabelBackgroundColor: '#CB0001',

  // Card Logo Container
  cardLogoContainerCornerRadiusTopLeft: '14',
  cardLogoContainerCornerRadiusTopRight: '14',
  cardLogoContainerCornerRadiusBottomLeft: '14',
  cardLogoContainerCornerRadiusBottomRight: '14',

  // cardDesign
  cardTextTitleColor: '#ffffff',
  cardTextValueColor: '#ffffff',
  cardHasShadow: false,
  cardShadowColor: '#222222',

  cardCornerRadiusTopLeft: '16',
  cardCornerRadiusTopRight: '16',
  cardCornerRadiusBottomRight: '16',
  cardCornerRadiusBottomLeft: '16',
  cardHoverButtonBgColor: '#548787',
  cardHoverButtonTextColor: '#87914f',
  cardHasBorder: false,
  cardBorderWidth: '',
  cardBorderColor: '',
  defaultCardBorderWidth: '0',

  /*
  Combine them into a single variable, cardBorderWidth. Additionally, introduce cardBorderDirection and cardHasBorder variables.
  Following these changes, implement a function in the Cashier Library to detect where to add the border, specifying the width and color accordingly.
  * */
  cardDesignBorderTop: '',
  cardDesignBorderBottom: '',
  cardDesignBorderLeft: '',
  cardDesignBorderRight: '',

  // slider
  sliderShape: 'rectangle',
  sliderCornerRadiusTopLeft: '20',
  sliderCornerRadiusTopRight: '20',
  sliderCornerRadiusBottomRight: '20',
  sliderCornerRadiusBottomLeft: '20',

  sliderArrowShadowColor: '#7d7d7d',

  // depositWithdrawalButton
  mainButtonBackgroundColor: '#59C4B0',
  mainButtonTextColor: '#0B1311',
  mainButtonHasBorder: false,
  mainButtonBorderWidth: '0',
  defaultMainButtonBorderWidth: '0',
  mainButtonBorderColor: '',
  mainButtonCornerRadiusTopLeft: '12',
  mainButtonCornerRadiusTopRight: '12',
  mainButtonCornerRadiusBottomRight: '12',
  mainButtonCornerRadiusBottomLeft: '12',

  /*
  Combine them into a single variable mainButtonBorderDirection.
  Following these changes, implement a function in the Cashier Library to detect where to add the border, specifying the width and color accordingly.
  * */
  mainButtonBorderDirection: '',

  // favoriteAmountButton
  favoriteButtonBackgroundColor: '#181818',
  favoriteButtonTextColor: '#ffffff',
  favoriteButtonBorderWidth: '1',
  favoriteButtonBorderColor: '#59c4b0',
  favoriteButtonCornerRadiusTopLeft: '12',
  favoriteButtonCornerRadiusTopRight: '12',
  favoriteButtonCornerRadiusBottomRight: '12',
  favoriteButtonCornerRadiusBottomLeft: '12',
  favoriteButtonHasBorder: false,

  favoriteButtonBorderDirection: '',
  defaultFavoriteButtonBorderWidth: '0',

  /*
  In both the Cashier Library and the Admin Cashier section, group the following variables:
  * */
  // notificationsBorderTop: '1px',
  // notificationsBorderLeft: '1px',
  // notificationsBorderRight: '1px',
  // notificationsBorderBottom: '1px',

  notificationsHasBorder: true,
  notificationsBorderWidth: '1',
  notificationsBorderColor: '#006ADC',

  notificationBorderColor: '#DEDEDE',
  notificationsCornerRadiusTopLeft: '16',
  notificationsCornerRadiusTopRight: '16',
  notificationsCornerRadiusBottomRight: '16',
  notificationsCornerRadiusBottomLeft: '16',

  notificationsPosition: 'topMiddle',

  // textButton
  textButtonBackgroundColor: '#59C4B0',
  tabTextButtonColor: '#59C4B0',
  generalIconsColor: '#fff',
  // textTitleColor
  textTitleColor: '#ffffff',
  // textContentColor
  textContentColor: '#E4E4E4',
  // hoverMotion
  hoverMotion: {},
  cardHoverMotion: 'zoom',

  // status State
  warningColor: '#f8c201',
  notificationWarningIconColor: '#f8c201',
  notificationWarningTitleColor: '#f8c201',
  notificationWarningBackgroundColor: '#f8c201',


  successColor: '#4ba031',
  notificationSuccessIconColor: '#4ba031',
  notificationSuccessTitleColor: '#666666',
  notificationSuccessBackgroundColor: '#fffff',

  infoColor: '#006ADC',
  notificationInfoBackgroundColor: '#e6f0fb',
  notificationInfoButtonColor: '#0076c2',

  warningBackgroundColor: '#ff0000',
  successBackgroundColor: '#1e271f',
  errorBackgroundColor: '#2c1c1c',
  infoBackgroundColor: '#17222d',

  /* Custom cashier variables */
  sliderLabelColor: '#ffffff',
  errorColor: '#cb2b31',
  labelBorderTopLeftRadius: '12px',
  labelBorderTopRightRadius: '12px',
  labelBorderBottomLeftRadius: '8px',
  labelBorderBottomRightRadius: '8px',
  labelBorderColor: '',

  hintCornerRadiusTopLeft: '4',
  hintCornerRadiusTopRight: '4',
  hintCornerRadiusBottomRight: '4',
  hintCornerRadiusBottomLeft: '4',

  mobileLogoContainerBorderTopLeftRadius: '4px',
  mobileLogoContainerBorderTopRightRadius: '4px',
  mobileLogoContainerBorderBottomLeftRadius: '4px',
  mobileLogoContainerBorderBottomRightRadius: '4px',
  mobileLogoContainerBorderColor: '#ededed',

  cardMobileLogoContainerBackground: '#ededed',

  generalCardBackgroundColor: '#313131',
  generalColorNeutralMinimum: '#ffffff',
  generalColorNeutralMaximum: '#000',
  overlayBackgroundColor: '#000000',

  // typography
  typographyFontSizeExtraSmall: '10px',
  typographyFontSizeSmall: '12px',
  typographyFontSizeMiddle: '14px',
  typographyFontSizeLarge: '16px',
  typographyFontWeightRegular: 400,
  typographyFontWeightMedium: 500,
  typographyFontWeightSemiBold: 600,
  typographyFontFamilyBase: 'Roboto, sans-serif',

  navigationTabVersion: {
    id: 1,
    label: 'Version 1',
    value: 'versionOne'
  },

  // tab versionONE GENERAL
  navigationVersionOneGeneralTextColor: '#1E2B47',
  navigationVersionOneGeneralBGColor: '#fff',
  navigationVersionOneGeneralHasBorder: true,
  navigationVersionOneGeneralBorderWidth: '1',
  navigationVersionOneGeneralBorderColor: '#1E2B47',
  navigationVersionOneGeneralBorderDirection: 'all',
  navigationVersionOneGeneralHasIcon: true,
  navigationVersionOneGeneralIconColor: '#1E2B47',
  navigationVersionOneGeneralCornerRadiusTopLeft: '30',
  navigationVersionOneGeneralCornerRadiusTopRight: '30',
  navigationVersionOneGeneralCornerRadiusBottomRight: '30',
  navigationVersionOneGeneralCornerRadiusBottomLeft: '30',
  // tab versionONE SELECTED
  navigationVersionOneSelectedTextColor: '#fff',
  navigationVersionOneSelectedBGColor: '#1E2B47',
  navigationVersionOneSelectedHasBorder: false,
  navigationVersionOneSelectedBorderWidth: '1',
  navigationVersionOneSelectedBorderColor: '#1E2B47',
  navigationVersionOneSelectedBorderDirection: 'all',
  navigationVersionOneSelectedIconColor: '#fff',

  // tab versionTWO GENERAL
  navigationVersionTwoGeneralIconColor: '#1E2B47',
  navigationVersionTwoGeneralBGColor: '#F7FAFF',
  navigationVersionTwoGeneralHasBorder: true,
  navigationVersionTwoGeneralBorderWidth: '1',
  navigationVersionTwoGeneralBorderColor: '#1E2B47',
  navigationVersionTwoGeneralBorderDirection: 'all',
  navigationVersionTwoGeneralSearchBGColor: '#2d5291',
  navigationVersionTwoGeneralSearchTextColor: '#2d5291',
  navigationVersionTwoGeneralSearchHasBorder: true,
  navigationVersionTwoGeneralSearchBorderColor: '#2d5291',
  navigationVersionTwoGeneralSearchBorderWidth: '1',
  navigationVersionTwoGeneralSearchBorderDirection: 'all',
  navigationVersionTwoGeneralSearchCornerRadiusTopLeft: '1',
  navigationVersionTwoGeneralSearchCornerRadiusTopRight: '1',
  navigationVersionTwoGeneralSearchCornerRadiusBottomRight: '1',
  navigationVersionTwoGeneralSearchCornerRadiusBottomLeft: '1',
  // tab versionTWO SELECTED
  navigationVersionTwoSelectedIconColor: '#fff',
  navigationVersionTwoSelectedTextColor: '#fff',
  navigationVersionTwoSelectedBGColor: '#1E2B47',
  navigationVersionTwoSelectedHasBorder: false,
  navigationVersionTwoSelectedBorderWidth: '1',
  navigationVersionTwoSelectedBorderColor: '#1E2B47',
  navigationVersionTwoSelectedBorderDirection: 'all',
  navigationVersionTwoSelectedCornerRadiusTopLeft: '20',
  navigationVersionTwoSelectedCornerRadiusTopRight: '20',
  navigationVersionTwoSelectedCornerRadiusBottomRight: '20',
  navigationVersionTwoSelectedCornerRadiusBottomLeft: '20',
  // tab versionTHREE GENERAL
  navigationVersionThreeGeneralIconColor: '#1E2B47',
  navigationVersionThreeGeneralTextColor: '#1E2B47',
  navigationVersionThreeGeneralBGColor: '#F7FAFF',
  navigationVersionThreeGeneralHasBorder: true,
  navigationVersionThreeGeneralBorderWidth: '1',
  navigationVersionThreeGeneralBorderColor: '#1E2B47',
  navigationVersionThreeGeneralBorderDirection: 'all',
  navigationVersionThreeGeneralSearchBGColor: '#2d5291',
  navigationVersionThreeGeneralSearchTextColor: '#2d5291',
  navigationVersionThreeGeneralSearchHasBorder: true,
  navigationVersionThreeGeneralSearchBorderColor: '#2d5291',
  navigationVersionThreeGeneralSearchBorderWidth: '1',
  navigationVersionThreeGeneralSearchBorderDirection: 'all',
  navigationVersionThreeGeneralSearchCornerRadiusTopLeft: '1',
  navigationVersionThreeGeneralSearchCornerRadiusTopRight: '1',
  navigationVersionThreeGeneralSearchCornerRadiusBottomRight: '1',
  navigationVersionThreeGeneralSearchCornerRadiusBottomLeft: '1',
  // tab versionThree SELECTED
  navigationVersionThreeSelectedIconColor: '#2D5291',
  navigationVersionThreeSelectedTextColor: '#2D5291',
  navigationVersionThreeSelectedBGColor: '#2D5291',
  navigationVersionThreeSelectedSelectorCornerRadiusTopLeft: '10',
  navigationVersionThreeSelectedSelectorCornerRadiusTopRight: '10',
  navigationVersionThreeSelectedSelectorCornerRadiusBottomRight: '0',
  navigationVersionThreeSelectedSelectorCornerRadiusBottomLeft: '0',

  /* History List*/

  // z-index
  zIndex9: 9,
  zIndex99: 99,
  zIndex999: 999,
  zIndex9999: 9999,
  zIndex99999: 99999,
  icons: {
    globeIcon: 'e900',
    videoIcon: 'e901',
    search: 'e902',
    processingTimeNotInstant: 'e903',
    processingTimeInstant: 'e904',
    apple: 'e905',
    arrowLeft: 'e906',
    arrowRight: 'e907',
    arrowDown: 'e908',
    arrowUp: 'e909',
    close: 'e90a',
    starFull: 'e90b',
    starBorder: 'e90c',
    warning: 'e90d',
    info: 'e90e',
    error: 'e90f',
    success: 'e910',
    download: 'e911'
  }
};
