import React, {
  memo, useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import Select, {components} from 'react-select';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {useTheme} from 'styled-components';
import {CheckBigIcon, UploadIcon} from '../../../../assets/icons';
import {arrayBufferToBase64} from '../../../../helpers/common';
import UploadedFileFieldForTemplateTwo from '../../../uploadedFileField/templateTwo/uploadedFileField';
import {CashierDetailsContext} from '../../../../contexts/cashierDetailsContext';
import {SelectedPaymentDetailsContext} from '../../../../contexts/selectedPaymentDetailsContext';

// styled components
import StyledInputWrapper, {
  StyledInputError,
  StyledUploadDocumentLabel,
  StyledUploadDocumentWrapper,
  StyledUploadedDocumentList
} from '../../../../styledComponents/input/input.styled';
import {customStyles} from '../../../../helpers/styled';
import {getDropdownListOptions} from '../../../../services/getDropdownListOptions';
import {DROPDOWN_ICONS} from '../../../../constants/common';
import {PaymentFormCallbacksContext} from '../../../../contexts/paymentFormCallbacksContext';
import StyledLabelInfoWrapper from '../../../../styledComponents/label/label.styled';


/**
 * DynamicDropdown Field
 */
const NewDynamicDropdownField = memo(({
  name,
  error,
  fieldControl,
  setFieldValue,
  getControlPropertyDescriptions,
  alertContext,
  isOffline
  // isMobile,
  // showVerificationHistoryIcon,
  // hasVerificationHistory
}) => {
  const theme = useTheme();
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(options[0]?.value);

  const {
    siteId, userId, currency, country, language, token
  } = useContext(
    CashierDetailsContext
  );
  const {paymentSystemId, platformPaymentSystemId} = useContext(
    SelectedPaymentDetailsContext
  );
  const {getVerificationFormControls} = useContext(
    PaymentFormCallbacksContext
  );

  useEffect(() => {
    setFieldValue(
      name,
      JSON.stringify(options?.find((option) => option.value === selected))
    );
  }, [options, selected]);

  /**
   * Fetch data for the first dropdown when the component mounts
   */
  useEffect(() => {
    if (fieldControl?.url?.value) {
      getDropdownListOptions({
        url: fieldControl.url.value,
        setOptions,
        setSelected,
        siteId,
        userId,
        paymentSystemId,
        currency,
        country,
        alertContext,
        languageId: language,
        isOffline,
        platformPaymentSystemId,
        token
      });
    }
  }, [siteId]);

  const Icon = useMemo(
    () => DROPDOWN_ICONS[fieldControl?.type?.value] ?? null,
    [fieldControl]
  );

  const optionsFiltered = useMemo(() => options?.filter((option) => option.value !== '-1'), [options]);
  const addNewOption = useMemo(() => options?.find((option) => option.value === '-1'), [options]);

  const onAddNewHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (addNewOption?.forVerificationFormControls) {
      getVerificationFormControls();
    }
  }, [addNewOption]);

  const handleSelectChange = (selectedOption) => {
    setSelected(selectedOption.value);
  };

  const customNoOptionsMessage = () => (
    <div className="custom-no-options-message">
      {getControlPropertyDescriptions.nooptions}
    </div>
  );

  return fieldControl?.hidden?.value
  && fieldControl?.hidden?.value.toString() === 'true' ? null : (
    <StyledInputWrapper>
      <Select
        id={name}
        key={name}
        name={name}
        placeholder={getControlPropertyDescriptions.placeholder}
        noOptionsMessage={customNoOptionsMessage}
        options={optionsFiltered}
        defaultValue={[optionsFiltered[0]]}
        value={optionsFiltered?.find((option) => option?.value === selected)}
        onChange={handleSelectChange}
        className={error ? 'react-select hasError' : 'react-select'}
        classNamePrefix="react-select"
        styles={customStyles(theme)}
        components={{
          // eslint-disable-next-line react/prop-types
          Control: ({children, ...props}) => (
            <components.Control {...props}>
              <Icon />
              {children}
            </components.Control>
          ),
          Option: ({
            // eslint-disable-next-line react/prop-types
            data, innerRef, innerProps
          }) => (
            <div
              ref={innerRef}
              {...innerProps}
            >
              {/* eslint-disable-next-line react/prop-types */}
              <span>{data.label}</span>
              {/* eslint-disable-next-line react/prop-types */}
              {data.value === selected ? (
                <CheckBigIcon />
              ) : null}
            </div>
          ),
          IndicatorSeparator: () => null
        }}
      />
      {!!addNewOption ? (
        <button type="button" onClick={onAddNewHandler}>+</button>
      ) : null}
      {error ? <StyledInputError>{error}</StyledInputError> : null}
    </StyledInputWrapper>
    );
});

NewDynamicDropdownField.propTypes = {
  error: PropTypes.string,
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  fieldControl: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  alertContext: PropTypes.object.isRequired,
  isOffline: PropTypes.bool.isRequired
  // isMobile: PropTypes.bool.isRequired,
  // showVerificationHistoryIcon: PropTypes.bool,
  // hasVerificationHistory: PropTypes.bool
};

NewDynamicDropdownField.defaultProps = {
  error: '',
  fieldControl: null
  // showVerificationHistoryIcon: true,
  // hasVerificationHistory: false
};

/**
 * Document Upload Field
 */
const DocumentUploadField = memo(
  ({
    name,
    setFile,
    isMobile,
    alertContext,
    controlProperties
  }) => {
    const {t} = useTranslation();

    const {
      fileformat,
      filesize,
      placeholder,
      uploaddocumentcount,
      validationfileformatmessage,
      validationfilesizemessage
    } = controlProperties;

    const maxUploadCount = Number(uploaddocumentcount);

    const [uploadedFiles, setUploadedFiles] = useState(
      Array.from({length: maxUploadCount}).reduce((acc, _, index) => {
        acc[index] = null;
        return acc;
      }, {})
    );

    const uploadedFilesValues = useMemo(() => Object.values(uploadedFiles), [uploadedFiles]);
    const uploadedFileValuesFiltered = useMemo(() => uploadedFilesValues.filter((file) => file !== null), [uploadedFilesValues]);

    const onChange = async(e) => {
      e.preventDefault();
      const file = e.target.files[0];

      if (!file) return;

      const firstEmptyIndex = uploadedFilesValues.indexOf(null);
      const buffer = await file?.arrayBuffer();
      const base64String = arrayBufferToBase64(buffer);

      const fileName = file?.name?.split('.')[0];
      const fileFormat = file?.name?.split('.')
        ?.pop()
        ?.toLowerCase();

      const fileSize = Number(filesize.split(/(\d+)/)
        .filter(Boolean)[0]);

      if (!!fileName && !!fileFormat) {
        if (!fileformat.includes(fileFormat)) {
          alertContext.error(validationfileformatmessage);
        } else if (file.size / 1024 / 1024 > Number(fileSize)) {
          alertContext.error(validationfilesizemessage);
        } else {
          const transformedData = {
            imageName: fileName,
            imageFormat: fileFormat,
            imageBase64Format: base64String,
            imagePreviewUrl: URL.createObjectURL(file)
          };
          setUploadedFiles((prev) => ({...prev, [firstEmptyIndex]: transformedData}));
        }
      }
    };

    useEffect(() => {
      setFile(uploadedFileValuesFiltered.map(({imageName, imageFormat, imageBase64Format}) => ({imageName, imageFormat, imageBase64Format})));
    }, [uploadedFileValuesFiltered]);

    return (
      <StyledUploadDocumentWrapper>
        <>
          <StyledUploadDocumentLabel htmlFor={name}>
            <UploadIcon />
            {`${placeholder} ${t('format')}: ${fileformat.split('.').join('').toUpperCase()} ${t('maxsize')}: ${filesize}MB`}
          </StyledUploadDocumentLabel>
          {uploadedFileValuesFiltered.length < maxUploadCount ? (
            <input
              type="file"
              name={name}
              id={name}
              onChange={onChange}
              accept={fileformat}
              hidden
            />
          ) : null}
        </>
        <StyledUploadedDocumentList>
          {uploadedFilesValues?.map((file, index) => (
            <UploadedFileFieldForTemplateTwo
              // eslint-disable-next-line react/no-array-index-key
              key={`${file?.imageName}-${index}`}
              isMobile={isMobile}
              fileName={file?.imageName}
              fileIndex={index}
              fileFormat={file?.imageFormat}
              filePreviewUrl={file?.imagePreviewUrl}
              deleteFileHandler={setUploadedFiles}
            />
          ))}
        </StyledUploadedDocumentList>
      </StyledUploadDocumentWrapper>
    );
  }
);

DocumentUploadField.propTypes = {
  name: PropTypes.string.isRequired,
  setFile: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  alertContext: PropTypes.object.isRequired,
  controlProperties: PropTypes.object.isRequired
};

const LabelField = function() {
  return <StyledLabelInfoWrapper>Label Field template 2</StyledLabelInfoWrapper>;
};

export const templateTwoSpecificFields = {
  NewDynamicDropdownField,
  DocumentUploadField,
  LabelField
};
