import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {StyledUploadedDocumentListItem, StyledUploadedDocumentName} from '../../../styledComponents/input/input.styled';
import {AttachIcon, DeleteIcon} from '../../../assets/icons';
import {StyledButtonWithoutBg} from '../../../styledComponents/button/button.styled';

function UploadedFileFieldForTemplateOne({
  fileName,
  deleteFileHandler
}) {
  const onDeleteHandler = () => {
    deleteFileHandler((prev) => prev.filter((item) => item.imageName !== fileName));
  };

  return (
    <StyledUploadedDocumentListItem>
      <AttachIcon />
      <StyledUploadedDocumentName>{fileName}</StyledUploadedDocumentName>
      <StyledButtonWithoutBg type="button" onClick={onDeleteHandler}>
        <DeleteIcon />
      </StyledButtonWithoutBg>
    </StyledUploadedDocumentListItem>
  );
}

UploadedFileFieldForTemplateOne.propTypes = {
  fileName: PropTypes.string.isRequired,
  deleteFileHandler: PropTypes.func.isRequired
};

export default memo(UploadedFileFieldForTemplateOne);
