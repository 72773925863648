import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {getCurrencySymbolOrCode} from '../../../../helpers/common';

/* Styled Components */
import {
  StyledPaymentItemFigcaptionList
} from '../web/paymentItemWeb.styled';
import {
  StyledPaymentHintMobile,
  StyledPaymentItemFigcaptionMobile,
  StyledPaymentItemLabelMobile,
  StyledPaymentItemLogoWrapperMobile,
  StyledPaymentItemMobile,
  StyledPaymentItemValueMobile
} from './paymentItemMobile.styled';
import {StyledFavoriteButtonMobile} from '../../../../styledComponents/button/button.styled';

/* Assets */
import {Favorite, NonFavorite} from '../../../../assets/icons';

function PaymentItemMobile({
  paymentInfo,
  handlePaymentSystemDetailsPage,
  isFavoriteGroup,
  onFavoriteClickHandler,
  currency,
  isCurrencySymbol
}) {
  const {t} = useTranslation();

  return (
    <StyledPaymentItemMobile
      onClick={() => handlePaymentSystemDetailsPage({
        paymentSystemId: paymentInfo?.paymentSystemId,
        platformPsId: paymentInfo?.platformPsId,
        disableMethod: paymentInfo?.disableMethod,
        unavailable: paymentInfo?.unavailable,
        hasRedirect: paymentInfo?.hasRedirect,
        hasVerifiedAccount: paymentInfo?.hasVerifiedAccount
      })}
    >
      <StyledPaymentItemLogoWrapperMobile>
        <img src={paymentInfo?.logoPath} alt={paymentInfo?.paymentSystemName} width="188" height="75" />
        {paymentInfo.isNew ? (
          <StyledPaymentHintMobile>
            {t(('new')?.toLowerCase())}
          </StyledPaymentHintMobile>
        ) : null}
        {paymentInfo.unavailable ? (
          <StyledPaymentHintMobile unavailable={paymentInfo?.unavailable}>
            {t(('underMaintenance')?.toLowerCase())}
          </StyledPaymentHintMobile>
        ) : null}
      </StyledPaymentItemLogoWrapperMobile>
      <StyledPaymentItemFigcaptionMobile
        isFavoriteGroup={isFavoriteGroup}
      >
        {/* {paymentInfo?.unavailable ? ( */}
        {/*   <StyledPaymentHintMessage> */}
        {/*     {t(('Method is temporary inactive')} */}
        {/*   </StyledPaymentHintMessage> */}
        {/* ) : ( */}
        <StyledPaymentItemFigcaptionList>
          {paymentInfo?.fee !== null ? (
            <li>
              <StyledPaymentItemLabelMobile>
                {t(('fee')?.toLowerCase())}
                :
                <StyledPaymentItemValueMobile>
                  {paymentInfo?.fee}
                  {' '}
                  %
                </StyledPaymentItemValueMobile>
              </StyledPaymentItemLabelMobile>
            </li>
          ) : null}
          <li>
            <StyledPaymentItemLabelMobile>
              {t(('min')?.toLowerCase())}
              :
              <StyledPaymentItemValueMobile>
                {paymentInfo.min}
                {' '}
                {getCurrencySymbolOrCode(currency, isCurrencySymbol)}
              </StyledPaymentItemValueMobile>
            </StyledPaymentItemLabelMobile>
          </li>
          <li>
            <StyledPaymentItemLabelMobile>
              {t(('max')?.toLowerCase())}
              :
              <StyledPaymentItemValueMobile>
                {paymentInfo.max}
                {' '}
                {getCurrencySymbolOrCode(currency, isCurrencySymbol)}
              </StyledPaymentItemValueMobile>
            </StyledPaymentItemLabelMobile>
          </li>
        </StyledPaymentItemFigcaptionList>
        {/* )} */}
      </StyledPaymentItemFigcaptionMobile>
      {isFavoriteGroup && (
        <StyledFavoriteButtonMobile
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onFavoriteClickHandler(paymentInfo.paymentSystemId, paymentInfo.isFavorit);
          }}
        >
          {paymentInfo.isFavorit
            ? <Favorite /> : <NonFavorite />}
        </StyledFavoriteButtonMobile>
      )}
    </StyledPaymentItemMobile>
  );
}

PaymentItemMobile.defaultProps = {
  paymentInfo: null,
  handlePaymentSystemDetailsPage: null,
  isFavoriteGroup: true,
  onFavoriteClickHandler: null,
  currency: null,
  isCurrencySymbol: null
};

PaymentItemMobile.propTypes = {
  paymentInfo: PropTypes.object,
  handlePaymentSystemDetailsPage: PropTypes.func,
  isFavoriteGroup: PropTypes.bool,
  onFavoriteClickHandler: PropTypes.func,
  currency: PropTypes.string,
  isCurrencySymbol: PropTypes.bool
};

export default PaymentItemMobile;
