import styled from 'styled-components';
import {
  StyledBonusList,
  StyledCardFigcaption,
  StyledCardFigcaptionDescription,
  StyledCardFigcaptionTitle,
  StyledPicture
} from '../web/bonusListWeb.styled';
import {StyledMainWrapper} from '../../../paymentsList/templateOne/web/paymentItemWeb.styled';

const StyledBonusListMobile = styled(StyledBonusList)`
    grid-template-columns: repeat(1, 1fr);
`;

const StyledPictureMobile = styled(StyledPicture)`
    height: 120px;
`;

const StyledCardFigcaptionMobile = styled(StyledCardFigcaption)`
    padding: 8px;
`;

const StyledInfoCardFigcaptionMobile = styled(StyledCardFigcaption)`
    padding: 0 0 16px 0;
`;

const StyledMainWrapperMobile = styled(StyledMainWrapper)`
    padding: 16px 12px;
    background-color: #111923;;
`;

/* Extend base styles and override only font-size */
const StyledCardFigcaptionTitleMobile = styled(StyledCardFigcaptionTitle)`
    font-size: ${({theme}) => theme.typographyFontSizeSmall};
    line-height: 16px;
`;

const StyledCardFigcaptionDescriptionMobile = styled(StyledCardFigcaptionDescription)`
    font-size: ${({theme}) => theme.typographyFontSizeSmall};
    line-height: 16px;
`;

export default StyledMainWrapperMobile;
export {
  StyledBonusListMobile,
  StyledPictureMobile,
  StyledCardFigcaptionMobile,
  StyledCardFigcaptionTitleMobile,
  StyledCardFigcaptionDescriptionMobile,
  StyledInfoCardFigcaptionMobile
};
