import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import BonusItem from './bonusItem';
import {generatePaymentDataForBonusList} from '../../../../helpers/common';

/* Styled Components */
import {
  StyledBackButtonTextMobile,
  StyledBackButtonWrapperMobile,
  StyledButtonWithoutBgWithMessageMobile
} from '../../../../styledComponents/button/button.styled';
import {StyledIconSmall} from '../../../../styledComponents/icon/icon.styled';
import StyledMainWrapperMobile, {StyledBonusListMobile} from './bonusesListMobile.styled';
import {StyledFlexContainerColumnForBonus} from '../web/bonusListWeb.styled';

function BonusesListMobile({
  bonusesList,
  simplifiedData,
  goToPaymentsList,
  setSelectedBonusHandler,
  navigateToBonusInfo,
  handlePaymentSystemDetailsPage
}) {
  const {t} = useTranslation();

  return (
    <StyledFlexContainerColumnForBonus>
      <StyledBackButtonWrapperMobile>
        <StyledButtonWithoutBgWithMessageMobile
          type="button"
          onClick={goToPaymentsList}
        >
          <StyledIconSmall icon="arrowLeft" fontSize="11px" />
          <StyledBackButtonTextMobile>
            {t(('backToPaymentList')?.toLowerCase())}
          </StyledBackButtonTextMobile>
        </StyledButtonWithoutBgWithMessageMobile>
      </StyledBackButtonWrapperMobile>
      <StyledMainWrapperMobile>
        <StyledBonusListMobile>
          {bonusesList.map(({
            id,
            mobileImage,
            shortDescription,
            name,
            payments
          }) => (
            <BonusItem
              key={id}
              id={id}
              imageUrl={mobileImage}
              name={name}
              shortDescription={shortDescription}
              payments={generatePaymentDataForBonusList(payments, simplifiedData)}
              setSelectedBonusHandler={setSelectedBonusHandler}
              navigateToBonusInfo={navigateToBonusInfo}
              handlePaymentSystemDetailsPage={handlePaymentSystemDetailsPage}
            />
          ))}
        </StyledBonusListMobile>
      </StyledMainWrapperMobile>
    </StyledFlexContainerColumnForBonus>
  );
}

BonusesListMobile.propTypes = {
  bonusesList: PropTypes.array.isRequired,
  simplifiedData: PropTypes.array.isRequired,
  goToPaymentsList: PropTypes.func.isRequired,
  setSelectedBonusHandler: PropTypes.func.isRequired,
  navigateToBonusInfo: PropTypes.func.isRequired,
  handlePaymentSystemDetailsPage: PropTypes.func.isRequired
};

export default BonusesListMobile;
