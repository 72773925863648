import React, {memo, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import GenerateCode from '../generateCode/generateCode';

function GeneratedActiveCodesList({
  isMobile,
  hideGoBackButton,
  codesList,
  onGoBackHandler,
  currency,
  isCurrencySymbol,
  alertContext
}) {
  const {t} = useTranslation();

  const [selectedCodeIndex, setSelectedCodeIndex] = useState(0);

  const moreThenOneCodes = useMemo(() => codesList.length > 1, [codesList]);

  const goToLeftHandler = () => {
    setSelectedCodeIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const goToRightHandler = () => {
    setSelectedCodeIndex((prev) => (prev < codesList.length - 1 ? prev + 1 : prev));
  };

  return (
    <div>
      <div style={{display: 'flex'}}>
        <div>
          <span>Icon</span>
          <span>{`${moreThenOneCodes ? `${codesList.length}` : ''} ${t(moreThenOneCodes ? 'activebarcodes' : 'activebarcode')}`}</span>
        </div>
        {moreThenOneCodes ? (
          <div style={{display: 'flex'}}>
            <button type="button" onClick={goToLeftHandler}>{'<'}</button>
            <div>{`${selectedCodeIndex + 1}/${codesList.length}`}</div>
            <button type="button" onClick={goToRightHandler}>{'>'}</button>
          </div>
        ) : null}
      </div>
      <div>
        <div>
          <GenerateCode
            codeDetailsData={codesList[selectedCodeIndex].data}
            translations={{}}
            setDataName={() => {}}
            alertContext={alertContext}
            currency={currency}
            isCurrencySymbol={isCurrencySymbol}
          />
        </div>
      </div>
      {hideGoBackButton ? null : <button type="button" onClick={onGoBackHandler}>{t('backToCodeGenerator'.toLowerCase())}</button>}
    </div>
  );
}

GeneratedActiveCodesList.propTypes = {
  isMobile: PropTypes.bool,
  hideGoBackButton: PropTypes.bool,
  codesList: PropTypes.array.isRequired,
  currency: PropTypes.string.isRequired,
  alertContext: PropTypes.object.isRequired,
  onGoBackHandler: PropTypes.func.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired
};

GeneratedActiveCodesList.defaultProps = {
  isMobile: false,
  hideGoBackButton: false
};

export default memo(GeneratedActiveCodesList);
