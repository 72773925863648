import styled, {css} from 'styled-components';
import {CODE_TYPE, MOBILE_SIZE, TABLET_SIZE} from '../../../../constants/common';
import {
  flexColumnContainer,
  flexContainer, fontStyle, generateBgImageUrl, truncate
} from '../../../../helpers/styled';

const StyledHistoryList = styled.ul`
  ${flexColumnContainer()};
  grid-gap: 16px;
  overflow: auto;
  flex: 1;
  padding-right: 6px;
`;

const StyledHistoryListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px dashed ${({theme}) => `${theme.textContentColor}30`};
  border-radius: 4px;
  color: ${(props) => props.theme.textTitleColor};
  grid-gap: 4px;
  padding: 8px 12px;
  cursor: pointer;
  
  ${(props) => props.selectedItem && css`
      border: 1px solid ${() => props.theme.textButtonBackgroundColor};
  `}
`;

const StyledHistoryListItemInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  grid-gap: 4px;
  
  @media screen and (max-width: ${TABLET_SIZE}) {
    flex-direction: column;
  }
`;

const StyledCycle = styled.span`
  width: 6px;
  height: 6px;
  background-color: ${(props) => props.theme.mainButtonBackgroundColor};
  border-radius: 50%;

  @media screen and (max-width: ${TABLET_SIZE}) {
    display: none
  }
`;

const StyledHistoryListItemTitle = styled.h5`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  ${fontStyle('regular')};

  @media screen and (max-width: ${TABLET_SIZE}) {
    width: 100%;
    justify-content: space-between;
  }
`;

const StyledViewWrapper = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 4px;
  margin-left: 12px;
  ${flexContainer()};
  background-color: ${(props) => props.theme.mainButtonBackgroundColor};

  &::after {
    position: absolute;
    content: '';
    left: -12px;
    display: block;
    height: 100%;
    border-left: 1px dashed ${({theme}) => `${theme.textContentColor}30`};
  }
`;

const StyledHistoryListItemValue = styled.span`
  ${fontStyle('semiBold')};
`;

const StyledHistoryDetailsDataWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 12px;
  
  ${(props) => props.isGeneratedCode && css`
    flex-direction: column;
    justify-content: center;
  `}

  ${(props) => props.isSeePDF && css`
    margin-bottom: -16px;
  `}
  
  @media screen and (max-width: ${MOBILE_SIZE}) {
    ${(props) => props.isGeneratedCode && css`
      
      h3 {
        display: none;
      }
    `}
    
    ${(props) => props.isSeePDF && css`
      h3 {
        display: block;
      }
    `}
  }
`;

const StyledGenerateCodeWrapper = styled.section`
  width: 100%;
  margin: 0 auto;
  padding: 0 64px;
  
  @media screen and (max-width: ${MOBILE_SIZE}) {
    padding: 0;
  }
`;

const StyledCodeBorderWrapped = styled.div`
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 16px;

  ${(props) => props.codeType === CODE_TYPE.BAR_CODE && css`
    background-image: url("${generateBgImageUrl(props, 140, 61, 37)}")
  `}

  ${(props) => props.codeType === CODE_TYPE.QR_CODE && css`
    background-image: url("${generateBgImageUrl(props, 100, 60, 54)}")
  `}
`;

const StyledCodeWrapper = styled.div`
  padding: 12px;
  border-radius: 4px;
  margin: 0 auto;
  background-color: ${(props) => props.theme.generalColorNeutralMinimum};

  ${(props) => props.codeType === CODE_TYPE.BAR_CODE && css`
    width: 260px;
    height: 100px;
  `}

  ${(props) => props.codeType === CODE_TYPE.QR_CODE && css`
    width: 140px;
    height: 140px;
  `}

  img, svg {
    width: 100%;
    height: 100%;
  }
`;

const StyledCopyContent = styled.div`
  max-width: 50%;
  display: flex;
  grid-gap: 4px;
`;

const StyledItemLabel = styled.h3`
  max-width: 50%;
  ${fontStyle('regular')};
  color: ${(props) => props.theme.cardTextTitleColor};
  ${truncate()};
`;

const StyledItemValue = styled.span`
  max-width: 50%;
  ${fontStyle('semiBold')};
  color: ${(props) => props.theme.cardTextValueColor};
  ${truncate()};
  
  ${(props) => props.descriptionValue && css`
    max-width: unset;
    margin: 0 auto;
    ${fontStyle('regular')};
    text-align: center;
    overflow: auto;
    white-space: normal;
  `}

  ${(props) => props.isCopy && css`
    max-width: unset;
  `}
`;

const StyledCodeLabel = styled.span`
  height: 24px;
  line-height: 24px;
  ${fontStyle('regular')};
  color: ${(props) => props.theme.cardTextValueColor};
`;

export default StyledHistoryList;
export {
  StyledHistoryListItem,
  StyledHistoryListItemTitle,
  StyledHistoryListItemValue,
  StyledCycle,
  StyledViewWrapper,
  StyledHistoryListItemInfoWrapper,
  StyledHistoryDetailsDataWrapper,
  StyledCodeWrapper,
  StyledItemLabel,
  StyledItemValue,
  StyledCodeBorderWrapped,
  StyledCodeLabel,
  StyledGenerateCodeWrapper,
  StyledCopyContent
};
