import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {getCurrencySymbolOrCode} from '../../../../helpers/common';

/* Styled Components */
import {
  StyledFavoriteButton
} from '../../../../styledComponents/button/button.styled';

/* Assets */
import {Favorite, NonFavorite} from '../../../../assets/icons';
import StyledPaymentItem, {
  StyledPaymentHint,
  StyledPaymentItemFigcaptionList,
  StyledPaymentItemLabel,
  StyledPaymentItemLogoWrapper,
  StyledPaymentItemValue
} from './paymentItem.styled';

function PaymentItemWebTemplateTwo({
  paymentInfo,
  handlePaymentSystemDetailsPage,
  isFavoriteGroup,
  onFavoriteClickHandler,
  currency,
  isCurrencySymbol
}) {
  const {t} = useTranslation();

  return (
    <StyledPaymentItem
      onClick={() => handlePaymentSystemDetailsPage({
        paymentSystemId: paymentInfo?.paymentSystemId,
        platformPsId: paymentInfo?.platformPsId,
        disableMethod: paymentInfo?.disableMethod,
        unavailable: paymentInfo?.unavailable,
        hasRedirect: paymentInfo?.hasRedirect,
        hasVerifiedAccount: paymentInfo?.hasVerifiedAccount
      })}
    >
      <StyledPaymentItemLogoWrapper>
        <img
          src={paymentInfo?.logoPath}
          alt={paymentInfo?.paymentSystemName}
          width="188"
          height="75"
        />
        {isFavoriteGroup && (
          <StyledFavoriteButton
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onFavoriteClickHandler(paymentInfo.paymentSystemId, paymentInfo.isFavorit);
            }}
          >
            {paymentInfo.isFavorit
              ? <Favorite /> : <NonFavorite />}
          </StyledFavoriteButton>
        )}
        {paymentInfo.isNew ? (
          <StyledPaymentHint
            cardHint
          >
            {t(('new')?.toLowerCase())}
          </StyledPaymentHint>
        ) : null}
        {paymentInfo.unavailable ? (
          <StyledPaymentHint
            cardHint
            unavailable={paymentInfo?.unavailable}
          >
            {t(('underMaintenance')?.toLowerCase())}
          </StyledPaymentHint>
        ) : null}
      </StyledPaymentItemLogoWrapper>
      <figcaption>
        <StyledPaymentItemFigcaptionList>
          {paymentInfo?.fee !== null ? (
            <li>
              <StyledPaymentItemLabel>
                {t(('fee')?.toLowerCase())}
                :
                <StyledPaymentItemValue>
                  {paymentInfo?.fee}
                  {' '}
                  %
                </StyledPaymentItemValue>
              </StyledPaymentItemLabel>
            </li>
          ) : null}
          <li>
            <StyledPaymentItemLabel>
              {t(('min')?.toLowerCase())}
              :
              <StyledPaymentItemValue>
                {paymentInfo.min}
                {' '}
                {getCurrencySymbolOrCode(currency, isCurrencySymbol)}
              </StyledPaymentItemValue>
            </StyledPaymentItemLabel>
          </li>
          <li>
            <StyledPaymentItemLabel>
              {t(('max')?.toLowerCase())}
              :
              <StyledPaymentItemValue>
                {paymentInfo.max}
                {' '}
                {getCurrencySymbolOrCode(currency, isCurrencySymbol)}
              </StyledPaymentItemValue>
            </StyledPaymentItemLabel>
          </li>
        </StyledPaymentItemFigcaptionList>
        {/* )} */}
      </figcaption>
    </StyledPaymentItem>
  );
}

PaymentItemWebTemplateTwo.defaultProps = {
  paymentInfo: null,
  handlePaymentSystemDetailsPage: null,
  isFavoriteGroup: true,
  onFavoriteClickHandler: null,
  currency: null,
  isCurrencySymbol: null
};

PaymentItemWebTemplateTwo.propTypes = {
  paymentInfo: PropTypes.object,
  handlePaymentSystemDetailsPage: PropTypes.func,
  isFavoriteGroup: PropTypes.bool,
  onFavoriteClickHandler: PropTypes.func,
  currency: PropTypes.string,
  isCurrencySymbol: PropTypes.bool
};

export default PaymentItemWebTemplateTwo;
