import styled from 'styled-components';
import {flexContainer, fontStyle, truncate} from '../../helpers/styled';

const StyledBannerWrapper = styled.section`
  ${flexContainer({
    justifyContent: 'flex-start'
  })};
  justify-content: flex-start;
  grid-gap: 8px;
  padding: 8px 12px 8px 16px;
  margin-bottom: 8px;
  border-radius: 12px;
  background: linear-gradient(89.27deg, #0B6095 -1.61%, #2299E4 100%);
  cursor: pointer;
  transition: 500ms;
    
  &:hover {
    padding-left: 20px;
  }
`;

const StyledBannerImageWrapper = styled.picture`
  img {
    max-width: 80px;
    height: 62px;
  }
`;
const StyledBannerInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: white;
    
    @media screen and (max-width: 800px) {
      i {
          display: none;
      }
    }
`;

const StyledBannerInfoTitle = styled.h2`
  ${fontStyle('semiBold')};
  font-size: 24px;
`;

const StyledBannerInfoTitleKey = styled.span`
  ${truncate()};
  color: ${(props) => props.theme.textButtonBackgroundColor};
`;

const StyledCardInfoDescription = styled.p`
  ${truncate()};
  ${fontStyle('regular')};
  font-size: ${(props) => props.theme.typographyFontSizeSmall};
  color: #eef0f6;
`;

export default StyledBannerWrapper;
export {
  StyledBannerInfoWrapper,
  StyledBannerImageWrapper,
  StyledBannerInfoTitle,
  StyledBannerInfoTitleKey,
  StyledCardInfoDescription
};
