import {css} from 'styled-components';
import {ALERT_TYPE} from '../constants/common';
import {theme} from '../theme/theme';

export const MOBILE_SIZE = '800px';

/**
 * Define disable style for elements
 *
 * If the button is disabled, these styles are added
 */

export function disable() {
  return css`
    opacity: ${(props) => (props.disabled ? '0.6' : '1')};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'inherit')};
  `;
}

/**
 * Define font style for elements
 *
 * @param {String} type
 *
 */
export function fontStyle(type) {
  switch (type) {
    case 'regular':
      return css`
      font: ${(props) => props.theme.typographyFontWeightRegular} 
            ${(props) => props.theme.typographyFontSizeMiddle}/normal 
            ${(props) => props.theme.typographyFontFamilyBase};
    `;
    case 'medium':
      return css`
        font: ${(props) => props.theme.typographyFontWeightRegular}
        ${(props) => props.theme.typographyFontSizeMiddle}/normal 
        ${(props) => props.theme.typographyFontFamilyBase};
`;
    default:
      return css`
      font: ${(props) => props.theme.typographyFontWeightRegular} 
            ${(props) => props.theme.typographyFontSizeSmall}/normal 
            ${(props) => props.theme.typographyFontFamilyBase};
`;
  }
}

export function generateAlertColor(state) {
  switch (state) {
    case ALERT_TYPE.SUCCESS:
      return `${theme.successColor}`;
    case ALERT_TYPE.WARNING:
      return `${theme.warningColor}`;
    case ALERT_TYPE.ERROR:
      return `${theme.errorColor}`;
    case ALERT_TYPE.INFO:
      return `${theme.infoColor}`;
    default:
      return `${theme.iconColor}`;
  }
}
export function generateAlertBackgroundColor(state) {
  switch (state) {
    case ALERT_TYPE.SUCCESS:
      return `${theme.successBackgroundColor}`;
    case ALERT_TYPE.WARNING:
      return `${theme.warningBackgroundColor}`;
    case ALERT_TYPE.ERROR:
      return `${theme.errorBackgroundColor}`;
    case ALERT_TYPE.INFO:
      return `${theme.infoBackgroundColor}`;
    default:
      return 'rgba(243, 244, 245, 0.7)';
  }
}
