import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {CashierDetailsContext} from '../../../../contexts/cashierDetailsContext';
import PaymentCardTemplateTwo from '../../../paymentCardTemplateTwo/paymentCardTemplateTwo';
import RenderPaymentForm from '../../common/renderPaymentForm';
import EnhancedFormik from '../../common/enhancedFormik';
import {formWebHOC} from '../../../../HOC/formWebHOC';
import {renderProcessingTime} from '../../../../helpers/common';
import GeneratedActiveCodesList from '../../../generatedActiveCodesList/generatedActiveCodesList';
import {TEMPLATE_TYPE} from '../../../../constants/common';

/* Styled Components */
import {
  StyledMainWrapper,
  StyledPaymentItemLabel,
  StyledPaymentItemValue
} from '../../../paymentsList/templateOne/web/paymentItemWeb.styled';
import {StyledFormWrapper} from '../../../../styledComponents/input/input.styled';
import {StyledFlexContainerForPsForm} from '../../templateOne/web/formWeb.styled';
import StyledSwitcherContainer, {StyledSwitchLabel} from '../../../toggle/toggled.styled';

function FormWebTemplateTwo({
  // paymentBalances,
  // paymentLimits,
  paymentGeneratedCodeInfo,
  // paymentDescription,
  applePaySession,
  baseUrl,
  alertContext,
  isCurrencySymbol,
  translations,
  payPalTransactionData,
  googlePayTransactionData,
  isDefaultAvailable,
  deviceType,
  // paymentSources,
  paymentControlsData,
  activeCodesList,
  showActiveCodesList,
  // newly added props
  isDefault,
  defaultToggleHandler,
  showGeneratedCodeInfo,
  closeActiveCodeListHandler,
  goBackToCodeGeneratorHandler
}) {
  const {currency} = useContext(CashierDetailsContext);
  const {t} = useTranslation();
  // const [dataName, setDataName] = useState('');

  return (
    <StyledMainWrapper>
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <StyledFlexContainerForPsForm>
        <StyledFormWrapper>
          <PaymentCardTemplateTwo
            currency={currency}
            isCurrencySymbol={isCurrencySymbol}
            expand={() => {}}
            paymentInfo={{
              imagePath: paymentControlsData?.imagePath,
              paymentSystemName: paymentControlsData?.paymentSystemName
            }}
          />
          {/* eslint-disable-next-line no-nested-ternary */}
          {showGeneratedCodeInfo ? (
            <GeneratedActiveCodesList
              translations={translations}
              alertContext={alertContext}
              currency={currency}
              isCurrencySymbol={isCurrencySymbol}
              codesList={[{data: paymentGeneratedCodeInfo}]}
              onGoBackHandler={goBackToCodeGeneratorHandler}
            />
          ) : showActiveCodesList && activeCodesList?.length > 0 ? (
            <GeneratedActiveCodesList
              codesList={activeCodesList}
              onGoBackHandler={closeActiveCodeListHandler}
              alertContext={alertContext}
              currency={currency}
              isCurrencySymbol={isCurrencySymbol}
            />
          ) : (
            <>
              <EnhancedFormik
                baseUrl={baseUrl}
                applePaySession={applePaySession}
                alertContext={alertContext}
                payPalTransactionData={payPalTransactionData}
                googlePayTransactionData={googlePayTransactionData}
                formComponent={RenderPaymentForm}
                deviceType={deviceType}
                currency={currency}
                isCurrencySymbol={isCurrencySymbol}
                paymentControlsData={paymentControlsData}
                templateType={TEMPLATE_TYPE.TEMPLATE_TWO}
              />
              {isDefaultAvailable && (
              <StyledSwitcherContainer>
                <input
                  type="checkbox"
                  checked={isDefault}
                  onChange={defaultToggleHandler}
                />
                <StyledSwitchLabel>{t(('setAsDefault')?.toLowerCase())}</StyledSwitchLabel>
              </StyledSwitcherContainer>
              )}
            </>
          )}
          {paymentControlsData?.processingTimeDetail ? (
            <div>
              <StyledPaymentItemLabel>
                {t(('transactionTime')?.toLowerCase())}
                :
                <StyledPaymentItemValue>
                  {renderProcessingTime(paymentControlsData?.processingTimeDetail, t)}
                </StyledPaymentItemValue>
              </StyledPaymentItemLabel>
            </div>
          ) : null}
        </StyledFormWrapper>
        <>
          DETAILS/HISTORY
        </>
      </StyledFlexContainerForPsForm>
    </StyledMainWrapper>
  );
}

FormWebTemplateTwo.defaultProps = {
  paymentGeneratedCodeInfo: null,
  // paymentDescription: null,
  // paymentSystemId: null,
  payPalTransactionData: null,
  googlePayTransactionData: null,
  // paymentBalances: null,
  // paymentLimits: null,
  isDefaultAvailable: false,
  // paymentSources: null
  activeCodesList: [],
  showActiveCodesList: false
};

FormWebTemplateTwo.propTypes = {
  deviceType: PropTypes.number.isRequired,
  paymentGeneratedCodeInfo: PropTypes.array,
  // paymentDescription: PropTypes.array,
  // paymentSystemId: PropTypes.number,
  applePaySession: PropTypes.bool.isRequired,
  baseUrl: PropTypes.string.isRequired,
  translations: PropTypes.object.isRequired,
  alertContext: PropTypes.object.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  payPalTransactionData: PropTypes.object,
  googlePayTransactionData: PropTypes.object,
  // paymentBalances: PropTypes.object,
  // paymentLimits: PropTypes.object,
  isDefaultAvailable: PropTypes.bool,
  // paymentSources: PropTypes.array,
  paymentControlsData: PropTypes.object.isRequired,
  activeCodesList: PropTypes.array,
  showActiveCodesList: PropTypes.bool,
  // newly added props
  isDefault: PropTypes.bool.isRequired,
  showGeneratedCodeInfo: PropTypes.bool.isRequired,
  defaultToggleHandler: PropTypes.func.isRequired,
  closeActiveCodeListHandler: PropTypes.func.isRequired,
  goBackToCodeGeneratorHandler: PropTypes.func.isRequired
};
export default formWebHOC(FormWebTemplateTwo);
