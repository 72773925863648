import React, {memo, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import BonusSecondaryPaymentSystems from '../../../bonusSecondaryPaymentSystems/bonusSecondaryPaymentSystems';

/* Styled Components */
import {
  StyledBonusListItem,
  StyledCardFigcaption,
  StyledCardFigcaptionDescription,
  StyledCardFigcaptionTitle,
  StyledCardFigure,
  StyledPicture
} from './bonusListWeb.styled';
import {
  StyledCardList,
  StyledCardListItem
} from '../../../bonusPaymentItem/bonusPaymentItem.styled';

function BonusItem({
  id,
  imageUrl,
  name,
  shortDescription,
  payments,
  setSelectedBonusHandler,
  navigateToBonusInfo,
  handlePaymentSystemDetailsPage
}) {
  const onClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedBonusHandler(id);
    navigateToBonusInfo();
  };

  const onPaymentClickHandler = useCallback((e, {
    disableMethod,
    paymentSystemId,
    unavailable,
    hasRedirect,
    hasVerifiedAccount
  }) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedBonusHandler(id);
    handlePaymentSystemDetailsPage({
      disableMethod,
      paymentSystemId,
      unavailable,
      hasRedirect,
      hasVerifiedAccount
    });
  }, []);

  const [initialPayments, secondaryPayments] = useMemo(() => {
    if (payments?.length > 0) {
      if (payments.length <= 6) return [payments, null];

      return [payments.slice(0, 5), payments.slice(5, payments.length)];
    }
    return [];
  }, [payments]);

  return (
    <StyledBonusListItem>
      <StyledCardFigure onClick={onClickHandler}>
        <StyledPicture>
          <img src={imageUrl} alt={name} className="bonusLargeBanner" />
        </StyledPicture>
        <StyledCardFigcaption>
          <StyledCardFigcaptionTitle>
            {name}
          </StyledCardFigcaptionTitle>
          <StyledCardFigcaptionDescription>
            {shortDescription}
          </StyledCardFigcaptionDescription>
          <StyledCardList>
            {/* On first page every Bonus Item */}
            {initialPayments?.map(({
              id: paymentSystemId,
              image,
              name: paymentSystemName,
              disableMethod,
              unavailable,
              hasRedirect,
              hasVerifiedAccount
            }) => (
              <StyledCardListItem
                key={paymentSystemId}
                onClick={(e) => onPaymentClickHandler(e, {
                  paymentSystemId,
                  disableMethod,
                  unavailable,
                  hasRedirect,
                  hasVerifiedAccount
                })}
              >
                <img src={image} alt={paymentSystemName} width="63" height="31" />
              </StyledCardListItem>
            ))}
            {secondaryPayments?.length ? (
              <BonusSecondaryPaymentSystems
                secondaryPayments={secondaryPayments}
                onPaymentClickHandler={onPaymentClickHandler}
              />
            ) : null}
          </StyledCardList>
        </StyledCardFigcaption>
      </StyledCardFigure>
    </StyledBonusListItem>
  );
}

BonusItem.propTypes = {
  id: PropTypes.number.isRequired,
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  payments: PropTypes.array.isRequired,
  setSelectedBonusHandler: PropTypes.func.isRequired,
  navigateToBonusInfo: PropTypes.func.isRequired,
  handlePaymentSystemDetailsPage: PropTypes.func.isRequired
};

export default memo(BonusItem);
