import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {getCurrencySymbolOrCode} from '../../helpers/common';

/* Styled Components */
import {StyledIconSmall} from '../../styledComponents/icon/icon.styled';
import {
  StyledPaymentItemLabel,
  StyledPaymentItemValue
} from '../paymentsList/templateOne/web/paymentItemWeb.styled';
import {SelectedPaymentDetailsContext} from '../../contexts/selectedPaymentDetailsContext';


function PaymentCardTemplateTwo({
  paymentInfo,
  currency,
  isCurrencySymbol,
  expand
}) {
  const paymentSystemDetail = useContext(SelectedPaymentDetailsContext);
  const {t} = useTranslation();

  return (
    <figure
      style={{display: 'flex'}}
      onClick={() => { console.log('expand', expand); }}
    >
      <picture>
        <img
          src={paymentInfo?.imagePath}
          alt={paymentInfo?.paymentSystemName}
          width="188"
          height="75"
        />
      </picture>
      <figcaption style={{display: 'flex', alignItems: 'center'}}>
        <div style={{flex: '1'}}>
          <h2>{paymentInfo?.paymentSystemName}</h2>
          <ul>
            <li>
              <StyledPaymentItemLabel>
                {t(('min')?.toLowerCase())}
                :
                <StyledPaymentItemValue>
                  {paymentSystemDetail?.min}
                  {' '}
                  {getCurrencySymbolOrCode(currency, isCurrencySymbol)}
                </StyledPaymentItemValue>
              </StyledPaymentItemLabel>
            </li>
            <li>
              <StyledPaymentItemLabel>
                {t(('max')?.toLowerCase())}
                :
                <StyledPaymentItemValue>
                  {paymentSystemDetail?.max}
                  {' '}
                  {getCurrencySymbolOrCode(currency, isCurrencySymbol)}
                </StyledPaymentItemValue>
              </StyledPaymentItemLabel>
            </li>
            {paymentSystemDetail?.fee !== null ? (
              <li>
                <StyledPaymentItemLabel>
                  {t(('fee')?.toLowerCase())}
                  :
                  <StyledPaymentItemValue>
                    {paymentSystemDetail?.fee}
                    {' '}
                    %
                  </StyledPaymentItemValue>
                </StyledPaymentItemLabel>
              </li>
            ) : null}
          </ul>
          <button type="button">Bonus</button>
        </div>
        <StyledIconSmall icon={expand ? 'arrowUp' : 'arrowDown'} fontSize="11px" />
      </figcaption>
    </figure>
  );
}

PaymentCardTemplateTwo.defaultProps = {
  paymentInfo: null,
  currency: null,
  isCurrencySymbol: null
};

PaymentCardTemplateTwo.propTypes = {
  paymentInfo: PropTypes.object,
  currency: PropTypes.string,
  isCurrencySymbol: PropTypes.bool,
  expand: PropTypes.func.isRequired
};

export default PaymentCardTemplateTwo;
