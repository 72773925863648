import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {StyledIconSmall} from '../../../../styledComponents/icon/icon.styled';
import BonusPaymentItem from '../../../bonusPaymentItem/bonusPaymentItem';
import {calculateBonusExpiration, decodeHTML, findMinMaxAmountsForBonus} from '../../../../helpers/common';

/* Styled Components */
import {
  StyledBackButtonTextMobile,
  StyledBackButtonWrapperMobile,
  StyledButtonWithoutBgWithMessageMobile
} from '../../../../styledComponents/button/button.styled';
import {
  StyledCardFigcaptionDescription,
  StyledCardFigcaptionTitle, StyledFlexContainerColumnForBonus
} from '../../../bonusesList/templateOne/web/bonusListWeb.styled';
import StyledInfoWrapperMobile, {
  StyledBonusInfoListMobile,
  StyledBonusInfoWrapperMobile,
  StyledBonusPaymentsListMobile,
  StyledPictureForNestedPageMobile
} from './bonusInfoMobile.styled';
import {StyledInfoCardFigcaptionMobile} from '../../../bonusesList/templateOne/mobile/bonusesListMobile.styled';
import {
  StyledBonusInfoContent,
  StyledBonusInfoDescription,
  StyledBonusInfoListItem,
  StyledBonusInfoListItemKey,
  StyledBonusInfoListItemValue
} from '../web/bonusInfoWeb.styled';

/* Assets */
import {
  Date, Id, MinDepositAmount, Type
} from '../../../../assets/icons';

function BonusInfoMobile({
  currency,
  bonusInfo,
  navigateToBonusesList,
  handlePaymentSystemDetailsPage
}) {
  const {t} = useTranslation();
  // const [seeAllPayments, setSeeAllPayments] = useState(false);

  const {
    mobileImage,
    id,
    name,
    conditions,
    payments,
    shortDescription,
    description,
    bonusType,
    expirationPeriod
  } = bonusInfo;
  const [minAmount] = findMinMaxAmountsForBonus(conditions);

  // const onSeeAllPaymentsToggleHandler = () => {
  //   setSeeAllPayments((prev) => !prev);
  // };

  const onPaymentClickHandler = useCallback((e, {
    disableMethod,
    paymentSystemId,
    unavailable,
    hasRedirect,
    hasVerifiedAccount
  }) => {
    e.preventDefault();
    e.stopPropagation();
    handlePaymentSystemDetailsPage({
      disableMethod,
      paymentSystemId,
      unavailable,
      hasRedirect,
      hasVerifiedAccount
    });
  }, []);

  return (
    <StyledFlexContainerColumnForBonus>
      {/* Start Back to Bonus List Button */}
      <StyledBackButtonWrapperMobile>
        <StyledButtonWithoutBgWithMessageMobile
          type="button"
          onClick={navigateToBonusesList}
        >
          <StyledIconSmall icon="arrowLeft" fontSize="11px" />
          <StyledBackButtonTextMobile>
            {t('backtobonuslist')}
          </StyledBackButtonTextMobile>
        </StyledButtonWithoutBgWithMessageMobile>
      </StyledBackButtonWrapperMobile>
      {/* End Back to Bonus List Button */}
      <StyledPictureForNestedPageMobile>
        <img src={mobileImage} alt={name} />
      </StyledPictureForNestedPageMobile>
      <StyledInfoWrapperMobile>
        <StyledInfoCardFigcaptionMobile>
          <StyledCardFigcaptionTitle>
            {name}
          </StyledCardFigcaptionTitle>
          <StyledCardFigcaptionDescription>
            {shortDescription}
          </StyledCardFigcaptionDescription>
        </StyledInfoCardFigcaptionMobile>
        <StyledBonusPaymentsListMobile>
          {payments?.map((payment) => (
            <BonusPaymentItem
              key={payment.id}
              isExpanded
              {...payment}
              onPaymentClickHandler={onPaymentClickHandler}
            />
          ))}
        </StyledBonusPaymentsListMobile>
        {/* {paymentSystems.length > 5 ? ( */}
        {/*   <StyledAddButtonWrapper> */}
        {/*     <StyledButtonWithoutBgWithMessage */}
        {/*       type="button" */}
        {/*       centerAlignedWithinHorizontalLines */}
        {/*       onClick={onSeeAllPaymentsToggleHandler} */}
        {/*     > */}
        {/*       <StyledIconSmall icon={seeAllPayments ? 'arrowUp' : 'arrowDown'} fontSize="11px" /> */}
        {/*       {t(seeAllPayments ? 'seelesspayments' : 'seeallpayments')} */}
        {/*     </StyledButtonWithoutBgWithMessage> */}
        {/*     <StyledLineSpan /> */}
        {/*   </StyledAddButtonWrapper> */}
        {/* ) : null} */}
        <StyledBonusInfoWrapperMobile>
          <StyledBonusInfoListMobile>
            <StyledBonusInfoListItem>
              <MinDepositAmount />
              <StyledBonusInfoListItemKey>
                {t('mindepositamount')}
                :
                <StyledBonusInfoListItemValue>
                  {`${minAmount} ${currency}`}
                </StyledBonusInfoListItemValue>
              </StyledBonusInfoListItemKey>
            </StyledBonusInfoListItem>
            <StyledBonusInfoListItem>
              <Id />
              <StyledBonusInfoListItemKey>
                {t('id')}
                :
                <StyledBonusInfoListItemValue>
                  {id}
                </StyledBonusInfoListItemValue>
              </StyledBonusInfoListItemKey>
            </StyledBonusInfoListItem>
            <StyledBonusInfoListItem>
              <Type />
              <StyledBonusInfoListItemKey>
                {t('type')}
                :
                <StyledBonusInfoListItemValue>
                  {bonusType}
                </StyledBonusInfoListItemValue>
              </StyledBonusInfoListItemKey>
            </StyledBonusInfoListItem>
            <StyledBonusInfoListItem>
              <Date />
              <StyledBonusInfoListItemKey>
                {t('expiration')}
                :
                <StyledBonusInfoListItemValue>
                  {calculateBonusExpiration(expirationPeriod)}
                </StyledBonusInfoListItemValue>
              </StyledBonusInfoListItemKey>
            </StyledBonusInfoListItem>
          </StyledBonusInfoListMobile>
          <StyledBonusInfoContent>
            <StyledBonusInfoDescription dangerouslySetInnerHTML={{
              __html: decodeHTML(description)
            }}
            />
          </StyledBonusInfoContent>
        </StyledBonusInfoWrapperMobile>
      </StyledInfoWrapperMobile>
    </StyledFlexContainerColumnForBonus>
  );
}

BonusInfoMobile.propTypes = {
  currency: PropTypes.string.isRequired,
  bonusInfo: PropTypes.object.isRequired,
  navigateToBonusesList: PropTypes.func.isRequired,
  handlePaymentSystemDetailsPage: PropTypes.func.isRequired
};

export default BonusInfoMobile;
