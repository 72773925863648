import {useEffect} from 'react';

export const useOutsideClick = (callback, refs, deps = []) => {
  const handleClick = (e) => {
    e.stopPropagation();
    if (refs?.every((ref) => ref.current && !ref.current.contains(e.target))) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener(`${'mousedown'}`, handleClick);
    return () => {
      document.removeEventListener(`${'mousedown'}`, handleClick);
    };
  }, deps);
};
