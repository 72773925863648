import axios from 'axios';
import {computeSha256HashFromString} from '../helpers/common';
import {BASE_URL, DEFAULT_HEADERS} from '../constants/common';

const instance = axios.create({
  baseURL: BASE_URL,
  headers: DEFAULT_HEADERS
});

function getAuthorization(config) {
  if (['post', 'get'].includes(config.method)) {
    const input = config.method === 'post'
      ? JSON.stringify(config.data || {})
      : Object.values(config.params || {}).join('');
    return computeSha256HashFromString(input);
  }
  return `Bearer ${localStorage.getItem('token')}`;
}

instance.interceptors.request.use((config) => {
  config.headers.Authorization = getAuthorization(config);
  return config;
});

export const getDropdownOptions = async(
  url,
  setOptions,
  siteId,
  userId,
  paymentSystemId,
  currency,
  country,
  alertContext,
  // optionType,
  // selectedBankValue,
  languageId,
  isOffline,
  platformPaymentSystemId,
  token
) => {
  let params;
  let headers = {
    siteId,
    userId,
    paymentSystemId
  };

  if (isOffline) {
    const concatenatedString = `${siteId}${platformPaymentSystemId}${userId}${languageId}${token}`;
    const inputHash = computeSha256HashFromString(concatenatedString);
    headers = {
      token,
      Authorization: inputHash
    };
  } else {
    params = {
      siteId,
      paymentSystemId,
      userId,
      countryCode: country,
      currency
    };
  }
  try {
    const response = await axios.get(url, {params, headers});
    const formattedOptions = response?.data?.map((option) => ({
      ...option,
      value: option?.id || option?.code,
      label: option.name
    }));
    setOptions(formattedOptions);
  } catch (error) {
    alertContext?.error(error);
  }
};

export default instance;
