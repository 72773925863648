import styled from 'styled-components';
import StyledBonusInfoWrapper, {StyledBonusInfoList} from '../web/bonusInfoWeb.styled';
import {StyledBonusPaymentsList} from '../../../bonusesList/templateOne/web/bonusListWeb.styled';

const StyledInfoWrapperMobile = styled.main`
    padding: 12px;
`;

const StyledPictureForNestedPageMobile = styled.picture`
  height: 130px;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const StyledBonusPaymentsListMobile = styled(StyledBonusPaymentsList)`
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    height: fit-content;
`;

const StyledBonusInfoWrapperMobile = styled(StyledBonusInfoWrapper)`
    flex-direction: column;
    grid-gap: 20px;
`;

const StyledBonusInfoListMobile = styled(StyledBonusInfoList)`
    width: 100%;
    flex: 1;
`;

export default StyledInfoWrapperMobile;
export {
  StyledBonusPaymentsListMobile,
  StyledPictureForNestedPageMobile,
  StyledBonusInfoWrapperMobile,
  StyledBonusInfoListMobile
};
