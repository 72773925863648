import styled from 'styled-components';
import {
  flexContainer,
  flexContainerWithGap,
  fontStyle,
  getBorder,
  getBorderRadius,
  getBoxShadow
} from '../../../../helpers/styled';

/**
 * Styled Group Name and Line Message
 * */
const StyledGroupName = styled.h2`
  ${flexContainerWithGap()};
  margin-bottom: 12px;
  ${fontStyle()};
  font-size: ${({theme}) => theme.typographyFontSizeSmall};
  color: ${({theme}) => theme.groupHeaderTitleColor};
  text-transform: capitalize;
`;

const StyledLine = styled.span`
  height: 1px;
  width: 100%;
  background-color: ${({theme}) => theme.groupHeaderBackgroundColor};
`;

/**
 * Styled Payment System List Wrapper
 */
const StyledPsWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(50% - 12px), 1fr));
  gap: 12px;
`;

const StyledPsWrapperMobile = styled(StyledPsWrapper)`
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  grid-template-rows: 78px;
`;

/**
 * Styled Payment System List Items
 */
const StyledPaymentItem = styled.figure`
  position: relative;
  ${flexContainerWithGap()};
  justify-content: flex-start;
  padding: 8px 12px 8px 8px;
  border: ${(props) => getBorder(props, 'card')}; // Automatically handles border width, color, and style
  border-radius: ${(props) => getBorderRadius(props, 'card')};
  box-shadow: ${({theme}) => getBoxShadow(theme)};
  background-color: ${({theme}) => theme.generalCardBackgroundColor};
  transition: 0.4s; /* 400ms is now 0.4s for brevity */
  cursor: pointer;
`;

const StyledPaymentItemSection = styled.div`
    margin-top: 8px;
`;

/**
 * Payment Item Mobile Logo Container
 */
const StyledPaymentItemLogoWrapper = styled.div`
  position: relative;
  ${flexContainer()};
  width: 80px;
  height: 62px;
  background-color: ${({theme}) => theme.generalColorNeutralMinimum};
  border-radius: ${(props) => getBorderRadius(props, 'cardLogoContainer')};

  img {
    width: 100%;
    height: 100%;
  }
`;

/**
 * Payment New / Under Maintenance Label
 */
const StyledPaymentHint = styled.span`
    /* Consider adding styles here if needed */
`;

/**
 * Payment Item List Container
 */
const StyledPaymentItemFigcaptionList = styled.ul`
  ${flexContainerWithGap()};
`;

/**
 * Styled Payment Item Label Colors
 */
const StyledPaymentItemLabel = styled.h3`
  ${flexContainerWithGap('2px')};
  justify-content: space-between;
  color: ${({theme}) => theme.cardTextTitleColor};
  font-weight: ${({theme}) => theme.typographyFontWeightRegular};
  font-size: ${({theme}) => theme.typographyFontSizeSmall};
  line-height: 1rem; /* Using rem for consistent sizing */
`;

/**
 * Styled Payment Item Value Colors
 */
const StyledPaymentItemValue = styled.span`
  ${fontStyle('semiBold')};
  font-size: ${({theme}) => theme.typographyFontSizeSmall};
  color: ${({theme}) => theme.cardTextValueColor};
`;

export default StyledPaymentItem;
export {
  StyledPsWrapper,
  StyledPaymentItemSection,
  StyledGroupName,
  StyledLine,
  StyledPaymentHint,
  StyledPaymentItemLogoWrapper,
  StyledPaymentItemFigcaptionList,
  StyledPaymentItemLabel,
  StyledPaymentItemValue,
  StyledPsWrapperMobile
};
