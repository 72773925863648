import React, {
  useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Reset as CSSReset} from 'styled-reset';
import {
  PaymentsForm,
  PaymentsHistory,
  PaymentsList,
  BonusesList,
  BonusInfo
} from '@paydrom/cashier-template-types';
import {ThemeProvider} from 'styled-components';
import Loader from '../loader/loader';
import AlertMessage from '../alertMessage/alertMessage';
import {ROUTES} from '../../constants/routes';
import AlertContext from '../../context/alertContext';
import {
  DEFAULT_LANGUAGE,
  DEVICE_SIZE,
  DEVICE_TYPE,
  OPERATION_TYPE_BY_NUMBER,
  PAYMENTS_IDS, CASHIER_MODE,
  BACK_TO_FORM_BUTTON_CONTROL
} from '../../constants/common';
import Config from '../../configs/mainConfig';
import {theme} from '../../theme/theme';

/* Api Calls*/
import {getPaymentSystemByMerchantUser} from '../../services/getPaymentSystemByMerchantUser';
import {merchantUserMultistepControls} from '../../services/merchantUserMultistepControls';
import {getSitePaymentSystemControls} from '../../services/getSitePaymentSystemControls';
import {getSiteActiveTemplateDetail} from '../../services/getSiteActiveTemplateDetail';
import {getTemplateTranslation} from '../../services/getTemplateTranslation';
import {getSitePaymentSystemDescriptions} from '../../services/getSitePaymentSystemDescriptions';
import {handleSubmitForm} from '../../services/handleSubmitForm';
import {getHistoryByPaymentSystem} from '../../services/getHistorByPaymentSystem';
// import {getPaymentLimits} from '../../services/getPaymentLimits';
import {getPaymentBalances} from '../../services/getPaymentBalances';
import {addRemoveFavorite} from '../../services/addRemoveFavorite';
import {updatePaymentDefaultStatus} from '../../services/updatePaymentDefaultStatus';
import {getVerificationHistory} from '../../services/getVerificationHistory';
import {getInitialDeviceType, gettingOperationType, onSafetyPayHelper} from '../../helpers/helperFunction';
import {merchantUserVerificationControls} from '../../services/merchantUserVerificationControls';
import {getMerchantUserBonuses} from '../../services/getMerchantUserBonuses';

/* Styled Components*/
import {StyledContentWrapper} from '../styledComponents/box/box.styled';

/* eslint-disable import/no-named-as-default-member, import/no-named-as-default */
const PaymentCashierRoute = function({
  siteId,
  userId,
  bonusId,
  gaMeasurementId,
  currency,
  country,
  token,
  language,
  isLoading,
  setIsLoading,
  applePaySession,
  cashierMode,
  analyticParameter
}) {
  const navigate = useNavigate();
  const alertContext = useContext(AlertContext);
  const [paymentSystemByMerchantUser, setPaymentSystemByMerchantUser] = useState([]);
  const [paymentControlsData, setPaymentControlsData] = useState([]);
  const [paymentGeneratedCodeInfo, setPaymentGeneratedCodeInfo] = useState([]);
  const [siteActiveTemplateDetail, setSiteActiveTemplateDetail] = useState({});
  const [customizationTheme, setCustomizationTheme] = useState({});
  const [templateTranslation, setTemplateTranslation] = useState({});
  const [sitePaymentSystemDescriptions, setSitePaymentSystemDescriptions] = useState();
  const [historyData, setHistoryData] = useState([]);
  const [psId, setPsId] = useState();
  const [platformId, setPlatformId] = useState();
  const [applePayTransactionData, setApplePayTransactionData] = useState(null);
  const [payPalTransactionData, setPayPalTransactionData] = useState(null);
  const [googlePayTransactionData, setGooglePayTransactionData] = useState(null);
  const [safetyPayTransactionData, setSafetyPayTransactionData] = useState(null);
  const [hasRedirectUrl, setHasRedirectUrl] = useState(false);
  const [hasIframe, setHasIframe] = useState(false);
  const [paymentBalances, setPaymentBalances] = useState(null);
  const [paymentLimits, setPaymentLimits] = useState(null);
  const [selectedPaymentDetails, setSelectedPaymentDetails] = useState(null);
  const [deviceType, setDeviceType] = useState(getInitialDeviceType());
  const [navigateToDefault, setNavigateToDefault] = useState(false);
  const [activeTab, setActiveTab] = useState(OPERATION_TYPE_BY_NUMBER.DEPOSIT);
  const operationTypeByNumber = gettingOperationType(cashierMode, activeTab);
  const [paymentSources, setPaymentSources] = useState(null);
  const [minAmountValue, setMinAmountValue] = useState(null);
  // for bonus logic
  const [bonusData, setBonusData] = useState(null);
  const [selectedBonusId, setSelectedBonusId] = useState(bonusId);

  const cashierDetails = {
    siteId,
    userId,
    paymentSystemId: psId,
    platformPaymentSystemId: platformId,
    operationType: operationTypeByNumber,
    currency,
    country,
    language: language || DEFAULT_LANGUAGE,
    token
  };

  /**
   * Handles the window resize event and updates the device type.
   */
  const handleResize = () => {
    setDeviceType(window.innerWidth < DEVICE_SIZE.MOBILE ? DEVICE_TYPE.MOBILE : DEVICE_TYPE.WEB);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const transactionDataSetters = {
    applePay: setApplePayTransactionData,
    payPal: setPayPalTransactionData,
    googlePay: setGooglePayTransactionData,
    safetyPay: setSafetyPayTransactionData
  };

  const simplifiedData = useMemo(() => (paymentSystemByMerchantUser.paymentMethods || [])
    .flatMap((el) => el.cashierPaymentSystem)
    .reduce((acc, curr) => {
      const {
        paymentSystemId,
        logoPath,
        isNew,
        platformPsId,
        paymentSystemName,
        disableMethod,
        unavailable,
        fee,
        min,
        max,
        multistep,
        processingTime,
        iframe,
        hasRedirect,
        hasVerifiedAccount,
        isOffline,
        isDefault,
        isFavorit,
        isVerificationFlow
      } = curr;

      if (!acc.ids.has(paymentSystemId)) {
        // Add payment system ID to the set
        acc.ids.add(paymentSystemId);

        // Push the unique payment system to the accumulator
        acc.data.push({
          logoPath,
          isNew,
          paymentSystemId,
          platformPaymentSystemId: platformPsId,
          paymentSystemName,
          disableMethod,
          unavailable,
          fee,
          min,
          max,
          multistep,
          processingTime,
          iframe,
          hasRedirect,
          hasVerifiedAccount,
          isOffline,
          isDefault,
          isFavorit,
          isVerificationFlow
        });
      }

      return acc;
    }, {data: [], ids: new Set()}).data, [paymentSystemByMerchantUser]);

  const safetyPayContextDataMemoized = useMemo(() => ({
    transactionData: safetyPayTransactionData,
    resetTransactionDataHandler: () => { setSafetyPayTransactionData(null); },
    onPayHandler: onSafetyPayHelper
  }), [safetyPayTransactionData]);

  const handlePaymentSystemDetailsPage = useCallback(({
    disableMethod,
    paymentSystemId,
    platformPsId,
    unavailable,
    hasRedirect,
    hasVerifiedAccount,
    fetchOnlyFormControls = false
  }) => {
    if (disableMethod || unavailable || !hasVerifiedAccount) {
      if (unavailable) {
        alertContext.info(templateTranslation?.paymentmethodunavailable);
      } else if (disableMethod) {
        alertContext.info(templateTranslation?.previouslydeposited);
      } else if (!hasVerifiedAccount) {
        alertContext.info(templateTranslation?.unverifiedaccounterrormessage);
      }
    } else {
      merchantUserMultistepControls(
        setPaymentControlsData,
        siteId,
        paymentSystemId,
        operationTypeByNumber,
        userId,
        currency,
        language,
        setIsLoading,
        alertContext
      ).then(() => {
        if (!fetchOnlyFormControls) {
          simplifiedData?.find((el) => {
            if (el.paymentSystemId === paymentSystemId) {
              setHasIframe(el.iframe);
              setMinAmountValue(el.min);
              return true;
            }
            return false;
          });
          setPsId(paymentSystemId);
          setSelectedPaymentDetails(simplifiedData?.find((el) => el.paymentSystemId === paymentSystemId));
          setPaymentGeneratedCodeInfo([]);
          setPlatformId(platformPsId);
          setHasRedirectUrl(hasRedirect);
          navigate(`/paymentsList/${paymentSystemId}`);
          setPaymentSources([]);
        }
      });
      if (!fetchOnlyFormControls) {
        getSitePaymentSystemDescriptions(
          setSitePaymentSystemDescriptions,
          siteId,
          paymentSystemId,
          operationTypeByNumber,
          language,
          setIsLoading,
          alertContext
        );
        // getPaymentLimits({
        //   setPaymentLimits,
        //   alertContext,
        //   setIsLoading,
        //   userId,
        //   paymentSystemId,
        //   siteId,
        //   paymentMethodType: operationTypeByNumber,
        //   languageISO: language,
        //   token
        // });
        if (operationTypeByNumber === OPERATION_TYPE_BY_NUMBER.WITHDRAWAL && (siteId === 120 || siteId === 169 || siteId === 170)) {
          getPaymentBalances({
            setPaymentBalances, alertContext, setIsLoading, userId, paymentSystemId, siteId, languageISO: language, token
          });
        }
      }
    }
  }, [operationTypeByNumber, simplifiedData]);

  useEffect(() => {
    if (userId && siteId && operationTypeByNumber) {
      getSiteActiveTemplateDetail(setSiteActiveTemplateDetail, setCustomizationTheme, siteId, setIsLoading, alertContext)
        .then((res) => {
          getPaymentSystemByMerchantUser(
            {
              setPaymentSystemByMerchantUser,
              siteId,
              userId,
              operationType: operationTypeByNumber,
              language,
              currency,
              country,
              token,
              setIsLoading,
              alertContext,
              applePaySession,
              navigateToDefault: res?.defaultPayment ? setNavigateToDefault : null
            }
          )
            .then((isBonusBanner) => {
              if (isBonusBanner && operationTypeByNumber === OPERATION_TYPE_BY_NUMBER.DEPOSIT) {
                getMerchantUserBonuses({
                  siteId, userId, language, setIsLoading, setBonusData
                });
              } else {
                setIsLoading(false);
              }
            });
        });
      getTemplateTranslation(setTemplateTranslation, siteId, language, setIsLoading, alertContext);
    }
  }, [siteId, userId, operationTypeByNumber, language, currency, country, token, setSiteActiveTemplateDetail, applePaySession, activeTab]);

  useEffect(() => {
    if (!selectedPaymentDetails && simplifiedData?.length > 0 && siteActiveTemplateDetail?.defaultPayment && navigateToDefault) {
      simplifiedData?.some(({
        isDefault,
        disableMethod,
        paymentSystemId,
        platformPaymentSystemId,
        unavailable,
        hasRedirect,
        hasVerifiedAccount
      }) => {
        if (isDefault && hasVerifiedAccount && !disableMethod && !unavailable) {
          handlePaymentSystemDetailsPage({
            disableMethod,
            paymentSystemId,
            platformPsId: platformPaymentSystemId,
            unavailable,
            hasRedirect,
            hasVerifiedAccount
          });
          return true;
        }
        return false;
      });
    }
  }, [selectedPaymentDetails, simplifiedData, siteActiveTemplateDetail?.defaultPayment, handlePaymentSystemDetailsPage, navigateToDefault]);

  const handleApplePayClick = (e) => {
    e.preventDefault();
    const transactionData = applePayTransactionData?.customScript;
    window.parent.postMessage({
      applePayClick: true,
      transactionData
    }, '*');
  };

  const onMessage = (e) => {
    if (e?.data?.applePayResponse) {
      getSitePaymentSystemControls(
        setPaymentControlsData,
        siteId,
        PAYMENTS_IDS.APPLE_PAY_ID,
        operationTypeByNumber,
        1,
        setIsLoading,
        alertContext
      );
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  const getSelectedTab = (tabId) => {
    getHistoryByPaymentSystem(
      setHistoryData,
      psId,
      siteId,
      userId,
      tabId,
      setIsLoading,
      alertContext
    );
  };

  const onFavoriteClickHandler = useCallback((paymentSystemId, isFavorite) => {
    if (!isFavorite && paymentSystemByMerchantUser?.paymentMethods?.find((group) => group.groupId === 103)?.cashierPaymentSystem?.length > 2) return;
    addRemoveFavorite({
      siteId,
      paymentSystemId,
      userId,
      paymentMethodType: operationTypeByNumber
    }).then(() => {
      getPaymentSystemByMerchantUser(
        {
          setPaymentSystemByMerchantUser,
          siteId,
          userId,
          operationType: operationTypeByNumber,
          language,
          currency,
          country,
          token,
          setIsLoading,
          alertContext,
          applePaySession
        }
      ).finally(() => { setIsLoading(false); });
    });
  }, [operationTypeByNumber, paymentSystemByMerchantUser?.paymentMethods, applePaySession]);

  const onPaymentDefaultStatusUpdateHandler = useCallback(({isDefault, callback}) => {
    updatePaymentDefaultStatus({
      siteId, userId, paymentMethodType: operationTypeByNumber, paymentSystemId: psId, isDefault, callback
    }).then(() => {
      getPaymentSystemByMerchantUser({
        setPaymentSystemByMerchantUser,
        siteId,
        userId,
        operationType: operationTypeByNumber,
        language,
        currency,
        country,
        token,
        setIsLoading,
        alertContext,
        applePaySession
      }).finally(() => { setIsLoading(false); });
    });
  }, [psId, applePaySession]);

  const backToPaymentsListHandler = useCallback((backFromBonusesList = false) => (e) => {
    e.stopPropagation();
    navigate('/paymentsList');
    if (!backFromBonusesList) {
      setSelectedPaymentDetails(null);
      setPaymentLimits(null);
      setPaymentBalances(null);
      setPsId(null);
      setPaymentControlsData([]);
      setSitePaymentSystemDescriptions([]);
      setNavigateToDefault(false);
    }
  }, []);

  const getVerificationFormControls = useCallback(() => {
    merchantUserVerificationControls(siteId)
      .then((response) => {
        response?.data?.fields?.push(BACK_TO_FORM_BUTTON_CONTROL);
        setPaymentControlsData((prev) => ({...response?.data, paymentSystemId: prev.paymentSystemId}));
        return response.data;
      }).catch((error) => {
        alertContext?.error(error?.response?.data);
        throw error;
      });
  }, [siteId, alertContext]);

  // callbacks for bonus logic
  const goToBonusHandler = useCallback(() => {
    navigate(ROUTES.BONUSES_LIST);
  }, []);

  const navigateToBonusInfo = useCallback(() => {
    navigate(ROUTES.BONUS_ITEM);
  }, []);

  const setSelectedBonusHandler = useCallback((id) => {
    setSelectedBonusId(id);
  }, []);

  const navigateToBonusesList = useCallback(() => {
    navigate(ROUTES.BONUSES_LIST);
    setSelectedBonusId(0);
  }, []);

  const generatePaymentsList = () => (
    <PaymentsList
      deviceType={deviceType}
      templateType={siteActiveTemplateDetail?.templateType}
      paymentData={paymentSystemByMerchantUser?.paymentMethods}
      customizationTheme={customizationTheme}
      handlePaymentSystemDetailsPage={handlePaymentSystemDetailsPage}
      translations={templateTranslation}
      language={language || DEFAULT_LANGUAGE}
      currency={currency}
      isCurrencySymbol={paymentSystemByMerchantUser?.isCurrencySymbol}
      enableSearch={paymentSystemByMerchantUser?.enableSearch}
      isFavoriteGroup={siteActiveTemplateDetail.favoriteGroup}
      onFavoriteClickHandler={onFavoriteClickHandler}
      hasTabs={cashierMode === CASHIER_MODE.BOTH}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      // for bonus logic
      isBonusBanner={operationTypeByNumber === OPERATION_TYPE_BY_NUMBER.DEPOSIT ? paymentSystemByMerchantUser?.isBonusBanner : false}
      bonusBannerUrl={operationTypeByNumber === OPERATION_TYPE_BY_NUMBER.DEPOSIT ? paymentSystemByMerchantUser?.bonusBannerUrl : null}
      goToBonusHandler={goToBonusHandler}
      // for profile update
      userAccountNotification={paymentSystemByMerchantUser?.userAccountNotification}
    />
  );

  return (
    <ThemeProvider theme={theme}>
      <AlertMessage />
      <CSSReset />
      <StyledContentWrapper>
        {isLoading && <Loader />}
        <Routes>
          {navigateToDefault ? null : (
            <>
              <Route path={ROUTES.PAYMENTS_LIST_DEFAULT} element={generatePaymentsList()} />
              <Route path={ROUTES.PAYMENTS_LIST} element={generatePaymentsList()} />
            </>
          )}
          <Route
            path={ROUTES.PAYMENT_ITEM}
            element={Object.keys(paymentControlsData)?.length ? (
              <PaymentsForm
                templateType={siteActiveTemplateDetail?.templateType}
                deviceType={deviceType}
                paymentDetails={selectedPaymentDetails}
                paymentControlsData={paymentControlsData}
                paymentBalances={paymentBalances}
                paymentLimits={paymentLimits}
                paymentGeneratedCodeInfo={paymentGeneratedCodeInfo}
                setPaymentGeneratedCodeInfo={setPaymentGeneratedCodeInfo}
                customizationTheme={customizationTheme}
                paymentDescription={sitePaymentSystemDescriptions}
                simplifiedData={applePaySession ? simplifiedData
                  : simplifiedData.filter((paymentSystem) => paymentSystem.paymentSystemId !== PAYMENTS_IDS.APPLE_PAY_ID)}
                handlePaymentSystemDetailsPage={handlePaymentSystemDetailsPage}
                handleSubmitForm={handleSubmitForm(
                  setIsLoading,
                  setPaymentControlsData,
                  setPaymentGeneratedCodeInfo,
                  hasIframe,
                  language,
                  setApplePayTransactionData,
                  setPayPalTransactionData,
                  setGooglePayTransactionData,
                  transactionDataSetters,
                  alertContext,
                  hasRedirectUrl,
                  templateTranslation,
                  selectedBonusId, // bonusId replaced with selectedBonusId
                  gaMeasurementId,
                  minAmountValue,
                  setPaymentSources,
                  analyticParameter
                )}
                translations={templateTranslation}
                navigate={navigate}
                baseUrl={Config.apiURLs.baseURL}
                cashierDetails={cashierDetails}
                applePaySession={applePaySession}
                handleApplePayClick={handleApplePayClick}
                alertContext={alertContext}
                isCurrencySymbol={paymentSystemByMerchantUser?.isCurrencySymbol}
                payPalTransactionData={payPalTransactionData}
                googlePayTransactionData={googlePayTransactionData}
                merchantUserMultistepControls={merchantUserMultistepControls}
                setPaymentControlsData={setPaymentControlsData}
                setIsLoading={setIsLoading}
                isDefaultAvailable={siteActiveTemplateDetail?.defaultPayment ?? false}
                onPaymentDefaultStatusUpdateHandler={siteActiveTemplateDetail?.defaultPayment ? onPaymentDefaultStatusUpdateHandler : null}
                backToListHandler={backToPaymentsListHandler()}
                getVerificationHistory={getVerificationHistory}
                getVerificationFormControls={getVerificationFormControls}
                paymentSources={paymentSources}
                safetyPayContextData={safetyPayContextDataMemoized}
              />
            ) : null}
          />
          <Route
            path={ROUTES.PAYMENT_ITEM_HISTORY}
            element={(
              <PaymentsHistory
                templateType={siteActiveTemplateDetail?.templateType}
                deviceType={deviceType}
                paymentControlsData={paymentControlsData}
                historyData={historyData}
                getSelectedTab={getSelectedTab}
                customizationTheme={customizationTheme}
                simplifiedData={applePaySession ? simplifiedData
                  : simplifiedData.filter((paymentSystem) => paymentSystem.paymentSystemId !== PAYMENTS_IDS.APPLE_PAY_ID)}
                handlePaymentSystemDetailsPage={handlePaymentSystemDetailsPage}
                navigate={navigate}
                cashierDetails={cashierDetails}
                translations={templateTranslation}
                isCurrencySymbol={paymentSystemByMerchantUser?.isCurrencySymbol}
                alertContext={alertContext}
                paymentDetails={selectedPaymentDetails}
                onSafetyPayHelper={onSafetyPayHelper}
              />
            )}
          />
          <Route
            path={ROUTES.BONUSES_LIST}
            element={(
              <BonusesList
                deviceType={deviceType}
                templateType={siteActiveTemplateDetail?.templateType}
                customizationTheme={customizationTheme}
                translations={templateTranslation}
                language={language || DEFAULT_LANGUAGE}
                bonusesList={bonusData?.bonusesList ?? []}
                simplifiedData={simplifiedData}
                goToPaymentsList={backToPaymentsListHandler(true)}
                navigateToBonusInfo={navigateToBonusInfo}
                setSelectedBonusHandler={setSelectedBonusHandler}
                handlePaymentSystemDetailsPage={handlePaymentSystemDetailsPage}
              />
              )}
          />
          <Route
            path={ROUTES.BONUS_ITEM}
            element={(
              <BonusInfo
                deviceType={deviceType}
                templateType={siteActiveTemplateDetail?.templateType}
                customizationTheme={customizationTheme}
                translations={templateTranslation}
                language={language || DEFAULT_LANGUAGE}
                currency={currency}
                bonusData={bonusData?.bonusesList?.find((bonus) => bonus.id === selectedBonusId)}
                simplifiedData={simplifiedData}
                navigateToBonusesList={navigateToBonusesList}
                handlePaymentSystemDetailsPage={handlePaymentSystemDetailsPage}
              />
            )}
          />
        </Routes>
      </StyledContentWrapper>
    </ThemeProvider>
  );
};

PaymentCashierRoute.propTypes = {
  siteId: PropTypes.number,
  userId: PropTypes.number,
  bonusId: PropTypes.string,
  gaMeasurementId: PropTypes.string,
  language: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  applePaySession: PropTypes.bool.isRequired,
  cashierMode: PropTypes.string.isRequired,
  analyticParameter: PropTypes.string
};

PaymentCashierRoute.defaultProps = {
  siteId: null,
  userId: null,
  bonusId: null,
  gaMeasurementId: null,
  analyticParameter: ''
};

export default PaymentCashierRoute;
