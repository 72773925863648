import React, {
  memo, useCallback, useContext, useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import {DeleteIcon} from '../../../../assets/icons';
import {arrayBufferToBase64, sortArrayByKeyValue} from '../../../../helpers/common';
import {CashierDetailsContext} from '../../../../contexts/cashierDetailsContext';
import {SelectedPaymentDetailsContext} from '../../../../contexts/selectedPaymentDetailsContext';
import {AccountVerificationHistoryContext} from '../../../../contexts/accountVerificationHistoryContext';
import {PaymentFormCallbacksContext} from '../../../../contexts/paymentFormCallbacksContext';
import {useOutsideClick} from '../../../../hooks/useOutSideClick';
import {getDropdownListOptions} from '../../../../services/getDropdownListOptions';
import {CARD_TYPE_ICONS, DROPDOWN_ICONS} from '../../../../constants/common';
import {deleteSingleOption} from '../../../../services/deleteSingleOption';
import UploadedFileFieldForTemplateOne from '../../../uploadedFileField/templateOne/uploadedFileField';
import {
  SelectOptionsContainer,
  SelectOptionsContainerMobile,
  SelectSingleOption,
  VerificationHistoryContainerMobile,
  VerificationHistoryContainerWeb
} from '../commonControlsType';

// styled components
import StyledInputWrapper, {
  StyledInputError,
  StyledInputLabel,
  StyledUploadDocumentLabel,
  StyledUploadDocumentWrapper,
  StyledUploadedDocumentList
} from '../../../../styledComponents/input/input.styled';
import {
  StyledButtonWithBorder,
  StyledButtonWithoutBg
} from '../../../../styledComponents/button/button.styled';
import {
  StyledCardIconWrapper,
  StyledSelect,
  StyledSelectOptionMobileWrapper,
  StyledSelectOptionsWrapper,
  StyledSelectText,
  StyledSelectWrapper
} from '../../../../styledComponents/select/select.styled';
import StyledLabelInfoWrapper, {
  StyledLabelInfo
} from '../../../../styledComponents/label/label.styled';

/**
 * New DynamicDropdown Field
 */
const NewDynamicDropdownField = memo(({
  name,
  error,
  fieldControl,
  setFieldValue,
  getControlPropertyDescriptions,
  alertContext,
  isOffline,
  isMobile,
  showVerificationHistoryIcon,
  hasVerificationHistory
}) => {
  const optionsRef = useRef();
  const showOptionsBtnRef = useRef();
  const [showOptions, setShowOptions] = useState(false);
  const [options, setOptions] = useState([]);
  const {
    siteId, userId, currency, country, language, token
  } = useContext(
    CashierDetailsContext
  );
  const {paymentSystemId, platformPaymentSystemId} = useContext(
    SelectedPaymentDetailsContext
  );
  const {clearVerificationHistoryHandler, verificationHistory} = useContext(
    AccountVerificationHistoryContext
  );
  const {getVerificationFormControls} = useContext(
    PaymentFormCallbacksContext
  );

  const [selected, setSelected] = useState(options[0]?.value);

  useOutsideClick(
    () => {
      setShowOptions(false);
    },
    [optionsRef, showOptionsBtnRef]
  );

  useEffect(() => {
    setFieldValue(
      name,
      JSON.stringify(options?.find((option) => option.value === selected))
    );
  }, [options, selected]);

  const isNew = options?.find((option) => option.value === selected)?.value === '-1';

  /**
   * Fetch data for the first dropdown when the component mounts
   */
  useEffect(() => {
    if (fieldControl?.url?.value) {
      getDropdownListOptions({
        url: fieldControl.url.value,
        setOptions,
        setSelected,
        siteId,
        userId,
        paymentSystemId,
        currency,
        country,
        alertContext,
        languageId: language,
        isOffline,
        platformPaymentSystemId,
        token
      });
    }
  }, [siteId]);

  const Icon = useMemo(
    () => DROPDOWN_ICONS[fieldControl?.type?.value] ?? null,
    [fieldControl]
  );

  const SelectedOptionIcon = useMemo(() => {
    const iconId = options?.find(
      (option) => option.value === selected?.value
    )?.cardScheme;
    return typeof iconId === 'number' ? CARD_TYPE_ICONS[iconId] : null;
  }, [selected, options]);

  const onOptionsClose = useCallback(() => {
    setShowOptions(false);
  }, []);

  const onSelect = useCallback((value, forVerificationFormControls) => {
    setShowOptions(false);
    if (forVerificationFormControls) {
      getVerificationFormControls();
    } else {
      setSelected(value);
    }
  }, []);

  const onDeleteOption = useCallback(
    (option) => () => {
      const sanitizedOption = {...option};
      delete sanitizedOption.label;
      delete sanitizedOption.value;
      const unbindProcessData = JSON.stringify(sanitizedOption);

      const url = fieldControl?.deleteactionurl?.value;
      if (url) {
        deleteSingleOption({
          url,
          siteId,
          platformPaymentSystemId,
          paymentSystemId,
          unbindProcessData,
          userId,
          languageId: language
        }).then(() => {
          if (fieldControl?.url?.value) {
            getDropdownListOptions({
              url: fieldControl.url.value,
              setOptions,
              setSelected,
              siteId,
              userId,
              paymentSystemId,
              currency,
              country,
              alertContext,
              languageId: language,
              isOffline,
              platformPaymentSystemId,
              token
            });
          }
        });
      }
    },
    [fieldControl]
  );

  const closeModalsHandler = useCallback(() => {
    setShowOptions(false);
    clearVerificationHistoryHandler();
  }, [clearVerificationHistoryHandler]);

  return fieldControl?.hidden?.value
  && fieldControl?.hidden?.value.toString() === 'true' ? null : (
    <StyledInputWrapper isNotRelative>
      {!isNew && options?.length === 1 && !hasVerificationHistory && !showVerificationHistoryIcon ? (
        <StyledInputLabel>
          {getControlPropertyDescriptions.name}
        </StyledInputLabel>
      ) : null}
      <StyledSelectWrapper spaceBetween>
        <StyledSelect isNew={isNew} hasNoCardIcon={!SelectedOptionIcon}>
          {!isNew && SelectedOptionIcon && (
            <StyledCardIconWrapper>
              <SelectedOptionIcon />
            </StyledCardIconWrapper>
          )}
          <StyledSelectText>
            {options?.find((option) => option.value === selected)?.name}
          </StyledSelectText>
          {(!isNew && options?.length === 1 && fieldControl?.withdeletableoptions?.value) ? (
            <StyledButtonWithoutBg
              type="button"
              onClick={onDeleteOption(options[0])}
            >
              <DeleteIcon />
            </StyledButtonWithoutBg>
          ) : null}
        </StyledSelect>
        {(options?.length > 1 || isNew || (hasVerificationHistory && showVerificationHistoryIcon)) ? (
          <StyledButtonWithBorder
            type="button"
            ref={showOptionsBtnRef}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowOptions((prev) => !prev);
            }}
          >
            {Icon ? <Icon /> : 'I'}
          </StyledButtonWithBorder>
        ) : null}
        {/* eslint-disable-next-line no-nested-ternary */}
        {isMobile ? (
          <>
            <SelectOptionsContainerMobile
              showInfo={showOptions && verificationHistory?.length === 0}
              optionsType={getControlPropertyDescriptions?.name}
              componentRef={optionsRef}
              onClose={onOptionsClose}
              showVerificationHistoryIcon={showVerificationHistoryIcon}
              hasVerificationHistory={hasVerificationHistory}
            >
              <StyledSelectOptionMobileWrapper className="withoutPaddingLeft">
                {sortArrayByKeyValue({
                  array: options,
                  key: 'value',
                  value: selected
                })?.map(
                  (option) => {
                    const {
                      label, value, forVerificationFormControls, cardScheme
                    } = option;

                    return (
                      <SelectSingleOption
                        key={value}
                        label={label}
                        value={value}
                        iconId={cardScheme}
                        isSelected={value === selected}
                        onSelect={onSelect}
                        onDelete={fieldControl?.withdeletableoptions?.value && value !== '-1' ? onDeleteOption(option) : null}
                        forVerificationFormControls={forVerificationFormControls}
                      />
                    );
                  }
                )}
              </StyledSelectOptionMobileWrapper>
            </SelectOptionsContainerMobile>
            <VerificationHistoryContainerMobile
              historyData={verificationHistory}
              onClose={closeModalsHandler}
              onGoBack={clearVerificationHistoryHandler}
            />
          </>
        ) // eslint-disable-next-line no-nested-ternary
          : verificationHistory?.length > 0 ? (
            <VerificationHistoryContainerWeb
              historyData={verificationHistory}
              onClose={clearVerificationHistoryHandler}
              iconType="arrowLeft"
            />
          ) : showOptions ? (
            <SelectOptionsContainer
              optionsType={getControlPropertyDescriptions.name}
              componentRef={optionsRef}
              onClose={onOptionsClose}
              showVerificationHistoryIcon={showVerificationHistoryIcon}
              hasVerificationHistory={hasVerificationHistory}
            >
              <StyledSelectOptionsWrapper>
                {sortArrayByKeyValue({
                  array: options,
                  key: 'value',
                  value: selected
                })?.map(
                  (option) => {
                    const {
                      label, value, forVerificationFormControls, cardScheme
                    } = option;

                    return (
                      <SelectSingleOption
                        key={value}
                        label={label}
                        value={value}
                        iconId={cardScheme}
                        isSelected={value === selected}
                        onSelect={onSelect}
                        onDelete={fieldControl?.withdeletableoptions?.value && value !== '-1' ? onDeleteOption(option) : null}
                        forVerificationFormControls={forVerificationFormControls}
                      />
                    );
                  }
                )}
              </StyledSelectOptionsWrapper>
            </SelectOptionsContainer>
          ) : null}
      </StyledSelectWrapper>
      {error ? <StyledInputError>{error}</StyledInputError> : null}
    </StyledInputWrapper>
    );
});

NewDynamicDropdownField.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  fieldControl: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  alertContext: PropTypes.object.isRequired,
  isOffline: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  showVerificationHistoryIcon: PropTypes.bool,
  hasVerificationHistory: PropTypes.bool
};

NewDynamicDropdownField.defaultProps = {
  error: '',
  fieldControl: null,
  showVerificationHistoryIcon: true,
  hasVerificationHistory: false
};

/**
 * Document Upload Field
 */
const DocumentUploadField = memo(
  ({
    name,
    setFile,
    controlProperties,
    alertContext
  }) => {
    const {
      fileformat,
      filesize,
      placeholder,
      uploaddocumentcount,
      validationfileformatmessage,
      validationfilesizemessage
    } = controlProperties;

    const [uploadedFiles, setUploadedFiles] = useState([]);

    const isUploadAvailable = useMemo(
      () => uploadedFiles.length < Number(uploaddocumentcount),
      [uploadedFiles.length, uploaddocumentcount]
    );

    const onChange = async(e) => {
      e.preventDefault();
      const file = e.target.files[0];
      const buffer = await file?.arrayBuffer();
      const base64String = arrayBufferToBase64(buffer);

      const fileName = file?.name?.split('.')[0];
      const fileFormat = file?.name?.split('.')
        ?.pop()
        ?.toLowerCase();

      const fileSize = Number(filesize.split(/(\d+)/)
        .filter(Boolean)[0]);

      if (!!fileName && !!fileFormat) {
        if (!fileformat.includes(fileFormat)) {
          alertContext.error(validationfileformatmessage);
        } else if (file.size / 1024 / 1024 > Number(fileSize)) {
          alertContext.error(validationfilesizemessage);
        } else {
          const transformedData = {
            imageName: fileName,
            imageFormat: fileFormat,
            imageBase64Format: base64String
          };
          setUploadedFiles((prev) => [...prev, transformedData]);
        }
      }
    };

    useEffect(() => {
      setFile(uploadedFiles);
    }, [uploadedFiles]);

    return (
      <StyledUploadDocumentWrapper>
        {isUploadAvailable && (
          <>
            <StyledUploadDocumentLabel htmlFor={name}>
              {placeholder}
            </StyledUploadDocumentLabel>
            <input
              type="file"
              name={name}
              id={name}
              onChange={onChange}
              accept={fileformat}
              hidden
            />
          </>
        )}
        {uploadedFiles.length > 0 && (
          <StyledUploadedDocumentList>
            {uploadedFiles.map((file, index) => (
              <UploadedFileFieldForTemplateOne
                // eslint-disable-next-line react/no-array-index-key
                key={`${file.imageName}-${index}`}
                fileName={file.imageName}
                fileIndex={index}
                deleteFileHandler={setUploadedFiles}
              />
            ))}
          </StyledUploadedDocumentList>
        )}
      </StyledUploadDocumentWrapper>
    );
  }
);

DocumentUploadField.propTypes = {
  name: PropTypes.string.isRequired,
  setFile: PropTypes.func.isRequired,
  controlProperties: PropTypes.object.isRequired,
  alertContext: PropTypes.object.isRequired
};

const LabelField = function({
  getControlPropertyDescriptions,
  fieldControl,
  ...props
}) {
  return fieldControl?.hidden?.value
  && fieldControl?.hidden?.value.toString() === 'true' ? null : (
    <StyledLabelInfoWrapper {...props}>
      <StyledLabelInfo
        dangerouslySetInnerHTML={{
          __html: getControlPropertyDescriptions.text
        }}
      />
    </StyledLabelInfoWrapper>
    );
};

LabelField.propTypes = {
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  fieldControl: PropTypes.object.isRequired
};

export const templateOneSpecificFields = {
  NewDynamicDropdownField,
  DocumentUploadField,
  LabelField
};
