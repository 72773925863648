import React, {memo, useState} from 'react';
import PropTypes from 'prop-types';
import BonusPaymentItem from '../bonusPaymentItem/bonusPaymentItem';

/* Styled Components */
import {
  StyledCardListIContainer,
  StyledCardListItem,
  StyledOptionsContainer, StyledOverflowContainer
} from '../bonusPaymentItem/bonusPaymentItem.styled';

function BonusSecondaryPaymentSystems({
  isMobile,
  secondaryPayments,
  onPaymentClickHandler,
  navigateToBonusInfoHandler
}) {
  const [showOptions, setShowOptions] = useState(false);

  const onClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowOptions((prev) => !prev);
  };

  return (
    <StyledCardListIContainer as="li">
      <StyledCardListItem
        as="p"
        onClick={isMobile ? navigateToBonusInfoHandler : onClickHandler}
      >
        +
        {secondaryPayments.length}
      </StyledCardListItem>
      {showOptions && !isMobile ? (
        <StyledOptionsContainer>
          <StyledOverflowContainer>
            {secondaryPayments.map(({
              id,
              name,
              image,
              disableMethod,
              unavailable,
              hasRedirect,
              hasVerifiedAccount
            }) => (
              <BonusPaymentItem
                key={id}
                id={id}
                name={name}
                image={image}
                disableMethod={disableMethod}
                unavailable={unavailable}
                hasRedirect={hasRedirect}
                hasVerifiedAccount={hasVerifiedAccount}
                onPaymentClickHandler={onPaymentClickHandler}
              />
            ))}
          </StyledOverflowContainer>
        </StyledOptionsContainer>
      ) : null}
    </StyledCardListIContainer>
  );
}

BonusSecondaryPaymentSystems.propTypes = {
  isMobile: PropTypes.bool,
  navigateToBonusInfoHandler: PropTypes.func,
  secondaryPayments: PropTypes.array.isRequired,
  onPaymentClickHandler: PropTypes.func.isRequired
};

BonusSecondaryPaymentSystems.defaultProps = {
  isMobile: false,
  navigateToBonusInfoHandler: null
};

export default memo(BonusSecondaryPaymentSystems);
