import React, {
  useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {PaymentFormCallbacksContext} from '../contexts/paymentFormCallbacksContext';
import {SelectedPaymentDetailsContext} from '../contexts/selectedPaymentDetailsContext';
import {DEFAULT_PAYMENT_ALERTS} from '../constants/common';
import {CashierDetailsContext} from '../contexts/cashierDetailsContext';
import {AccountVerificationHistoryContext} from '../contexts/accountVerificationHistoryContext';

export const formWebHOC = (FormWeb) => function FormWebHOC(props) {
  FormWebHOC.propTypes = {
    deviceType: PropTypes.number.isRequired,
    paymentGeneratedCodeInfo: PropTypes.array,
    setPaymentGeneratedCodeInfo: PropTypes.func,
    paymentDescription: PropTypes.array,
    paymentSystemId: PropTypes.number,
    applePaySession: PropTypes.bool.isRequired,
    baseUrl: PropTypes.string.isRequired,
    translations: PropTypes.object.isRequired,
    alertContext: PropTypes.object.isRequired,
    isCurrencySymbol: PropTypes.bool.isRequired,
    payPalTransactionData: PropTypes.object,
    googlePayTransactionData: PropTypes.object,
    paymentBalances: PropTypes.object,
    paymentLimits: PropTypes.object,
    isDefaultAvailable: PropTypes.bool,
    onPaymentDefaultStatusUpdateHandler: PropTypes.func,
    paymentSources: PropTypes.array,
    paymentControlsData: PropTypes.object.isRequired,
    activeCodesList: PropTypes.array,
    showActiveCodesList: PropTypes.bool
  };
  FormWebHOC.defaultProps = {
    paymentGeneratedCodeInfo: null,
    setPaymentGeneratedCodeInfo: null,
    paymentDescription: null,
    paymentSystemId: null,
    payPalTransactionData: null,
    googlePayTransactionData: null,
    paymentBalances: null,
    paymentLimits: null,
    isDefaultAvailable: false,
    onPaymentDefaultStatusUpdateHandler: null,
    paymentSources: null,
    activeCodesList: [],
    showActiveCodesList: false
  };

  // props
  const {
    setPaymentGeneratedCodeInfo,
    paymentGeneratedCodeInfo,
    onPaymentDefaultStatusUpdateHandler,
    alertContext,
    ...restProps
  } = props;

  // context
  const {operationType, siteId, userId} = useContext(CashierDetailsContext);
  const {isDefault: initialIsDefault, paymentSystemId} = useContext(SelectedPaymentDetailsContext);
  const {getVerificationHistory, setGeneratedActiveCodesList, openActiveCodesList} = useContext(PaymentFormCallbacksContext);

  // state
  const [showGeneratedCodeInfo, setShowGeneratedCodeInfo] = useState(false);
  const [isDefault, setIsDefault] = useState(initialIsDefault);
  const [verificationHistory, setVerificationHistory] = useState([]);

  const {t} = useTranslation();

  /**
     * Handle hide additional payment control info
     */
  const goBackToCodeGeneratorHandler = useCallback(() => {
    setPaymentGeneratedCodeInfo([]);
    setShowGeneratedCodeInfo(false);
  }, [setPaymentGeneratedCodeInfo, setShowGeneratedCodeInfo]);

  /**
     * Handle close active codes list
     */
  const closeActiveCodeListHandler = useCallback(() => {
    setGeneratedActiveCodesList([]);
    openActiveCodesList(false);
  }, [setGeneratedActiveCodesList, openActiveCodesList]);

  /**
     * Handle payment default status toggle
     */
  const defaultToggleHandler = useCallback(() => {
    onPaymentDefaultStatusUpdateHandler({
      isDefault: !isDefault,
      callback: (res) => {
        if (res.data) {
          alertContext?.success(t((DEFAULT_PAYMENT_ALERTS[operationType][isDefault ? 'unSet' : 'set']).toLowerCase()));
          setIsDefault((prev) => !prev);
        } else {
          alertContext?.error(t((DEFAULT_PAYMENT_ALERTS[operationType].error).toLowerCase()));
        }
      }
    });
  }, [isDefault, alertContext]);

  const verificationHistoryContextMemoized = useMemo(() => ({
    getVerificationHistoryHandler: () => {
      getVerificationHistory({
        siteId,
        userId,
        paymentSystemId,
        setVerificationHistory
      });
    },
    clearVerificationHistoryHandler: () => {
      setVerificationHistory([]);
    },
    verificationHistory
  }), [verificationHistory]);


  /**
     * Effect for handling initialIsDefault change in case of payment details change
     */
  useEffect(() => {
    setIsDefault(initialIsDefault);
  }, [initialIsDefault, paymentSystemId]);

  /**
     * Effect to handle changes in paymentGeneratedCodeInfo fields length
     */
  useEffect(() => {
    const hasGeneratedInfo = paymentGeneratedCodeInfo?.length > 0;
    setShowGeneratedCodeInfo(hasGeneratedInfo);
  }, [paymentGeneratedCodeInfo]);

  return (
    <AccountVerificationHistoryContext.Provider value={verificationHistoryContextMemoized}>
      <FormWeb
        {...restProps}
        isDefault={isDefault}
        alertContext={alertContext}
        defaultToggleHandler={defaultToggleHandler}
        showGeneratedCodeInfo={showGeneratedCodeInfo}
        paymentGeneratedCodeInfo={paymentGeneratedCodeInfo}
        closeActiveCodeListHandler={closeActiveCodeListHandler}
        goBackToCodeGeneratorHandler={goBackToCodeGeneratorHandler}
      />
    </AccountVerificationHistoryContext.Provider>
  );
};
