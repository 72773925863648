import instance from './apiService';

export const deleteSingleOption = async({
  url,
  siteId,
  userId,
  paymentSystemId,
  unbindProcessData
}) => {
  await instance.post(url, {
    userId: JSON.stringify(userId),
    paymentSystem: paymentSystemId,
    siteId,
    transactionType: 1,
    UnbindProcessData: unbindProcessData
  });
};
