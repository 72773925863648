import styled, {css} from 'styled-components';
import {
  flexColumnContainer, flexContainer, fontStyle, getBorderRadius
} from '../../../../helpers/styled';
import {MOBILE_SIZE} from '../../../../constants/common';

const StyledFlexContainerForPsForm = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  overflow: hidden;
  background-color: ${({theme}) => theme.generalCardBackgroundColor};
  border-radius: ${(props) => getBorderRadius(props, 'card')}; // Handles border-radius for all corners
`;

const StyledPaymentFormWrapper = styled.form`
  ${flexColumnContainer()};
  grid-gap: 24px;
  padding: 0 64px;

  ${({autoGeneratedForm}) => autoGeneratedForm && css`
    grid-gap: 0;
  `}

  @media screen and (max-width: ${MOBILE_SIZE}) {
    padding: 0;
  }
`;

const StyledPaymentDetailWrapper = styled.div`
  position: relative;
  ${flexContainer};
  padding: 16px;
  grid-gap: 16px;
  height: 56px;
`;

const StyledMainFormAndInfoWrapper = styled.div`
  ${flexColumnContainer()};
  padding: 24px 16px 16px;
    
  ${(props) => props.minHeight && css`
    overflow: hidden;
    flex: 1;
  `}
`;

const StyledNoDataMessage = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  ${fontStyle('regular')};
  color: ${(props) => props.theme.textTitleColor};
`;

const StyledPaymentName = styled.h2`
  ${fontStyle('semiBold')};
  font-size: ${(props) => props.theme.typographyFontSizeLarge};
  color: ${(props) => props.theme.textTitleColor};
`;

const StyledPaymentInfoList = styled.ul`
  ${flexContainer};
  flex-wrap: wrap;
  grid-gap: 16px;
  padding: 0 16px 16px;
  border-bottom: 1px dashed ${({theme}) => `${theme.textContentColor}30`};
`;

const StyledPaymentInfoRightSide = styled.div`
  width: 40%;
  padding: 16px;
  border-left: 1px dashed ${({theme}) => `${theme.textContentColor}30`};
  
  ul {
    padding-left: 24px;
    
    li {
      list-style-type: disc;
    }
  }
`;

const StyledPaymentInfoRightSideForHistory = styled(StyledPaymentInfoRightSide)`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const StyledFlexContainerColumn = styled.div`
  ${flexColumnContainer()};
  gap: ${({gap}) => gap || '8px'};
    
  ${(props) => props.fullWidth && css`
    width: 100%;
  `}
`;

const StyledCardHeaderWrapper = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const StyledPaymentInfoTitle = styled.h2`
  flex: 1;
  margin-bottom: 8px;
  ${fontStyle('semiBold')};
  font-size: ${({theme}) => theme.typographyFontSizeMiddle};
  line-height: 24px;
  color: ${(props) => props.theme.textTitleColor};
`;

const StyledCardHeaderTitle = styled(StyledPaymentInfoTitle)`
  margin: 0;
  text-align: center;
  text-transform: capitalize;
`;

const StyledPaymentInfoParagraph = styled.div`
  margin-bottom: 16px;
  ${fontStyle('regular')};
  font-size: ${({theme}) => theme.typographyFontSizeMiddle};
  line-height: 24px;
  color: ${(props) => props.theme.textContentColor};
  
  ul {
    li {
      color: ${(props) => props.theme.textContentColor};
      
      &::marker {
        color: ${(props) => props.theme.generalIconsColor};
      }
    }
  }
  
  a {
    color: ${(props) => props.theme.textButtonBackgroundColor};
  }
`;

const StyledLinkItem = styled.a`
  text-decoration: underline;
  ${fontStyle('medium')};
  color: ${(props) => props.theme.textButtonBackgroundColor};
`;

const StyledBonusSectionWrapper = styled.figure`
  ${flexColumnContainer()};
  justify-content: center;
  grid-gap: 16px;
  padding: 12px 0;
`;

const StyledSectionIconWrapper = styled.picture`
  width: 80px;
  height: 80px;
  margin: 0 auto;
`;

const StyledSectionInfoWrapper = styled.figcaption`
  ${flexContainer};
  flex-direction: column;
  grid-gap: 8px;
  margin-bottom: 12px;
`;

const StyledSectionHeading = styled.h3`
  ${fontStyle('semiBold')};
  font-size: ${({theme}) => theme.typographyFontSizeLarge};
  color: ${(props) => props.theme.textTitleColor};
`;

const StyledSectionParagraph = styled.p`
  ${fontStyle('regular')};
  color: ${(props) => props.theme.textContentColor};
  text-align: center;

  a {
    color: ${(props) => props.theme.textButtonBackgroundColor};
    text-decoration: underline;
  }
`;

const StyledButtonWrapper = styled.div`
  ${flexContainer};
  grid-gap: 8px;
`;

export {
  StyledPaymentFormWrapper,
  StyledFlexContainerForPsForm,
  StyledPaymentDetailWrapper,
  StyledPaymentInfoList,
  StyledCardHeaderTitle,
  StyledPaymentInfoRightSide,
  StyledFlexContainerColumn,
  StyledCardHeaderWrapper,
  StyledPaymentInfoTitle,
  StyledPaymentInfoParagraph,
  StyledLinkItem,
  StyledMainFormAndInfoWrapper,
  StyledPaymentName,
  StyledNoDataMessage,
  StyledPaymentInfoRightSideForHistory,
  StyledBonusSectionWrapper,
  StyledSectionIconWrapper,
  StyledSectionInfoWrapper,
  StyledSectionHeading,
  StyledSectionParagraph,
  StyledButtonWrapper
};
